import React, { useRef } from 'react'
import "../../Assets/Styles/Sections/Section8.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Section8 = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <footer className='footer-one footer-bg  position-relative'>
            <div className='iq-subscribe pattern-left  container position-relative lets-discuss-main' id="iq-subscribe">
                <div className='container'>
                    <div className='container row'>
                        <div class="col-sm-4">
                            <div class="section-titles">
                                <h2 class="title iq-fw-8">Let's Discuss</h2>
                                <p className='offerPara'>Please fill the form and we will get back to you same day.</p>
                            </div>
                        </div>
                        <div className='col-sm-8 text-center'>
                            <form ref={form} onSubmit={sendEmail}>
                                <div class="row justify-content-center">
                                    <div class="col-lg-12">
                                        <div class="project-form mb-3 lets-discuss-form">
                                            <div class="three-row">
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="first-name">
                                                        <input
                                                            required
                                                            type="text"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control contact-first-name"
                                                            id="ContactForm1_contact-form-phone"
                                                            name="user_name"
                                                            size="40"
                                                            placeholder="Full Name"
                                                        />
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="phone">
                                                        <input
                                                            required
                                                            type="tel"
                                                            name="user_phone"
                                                            size="10"
                                                            id="ContactForm1_contact-form-phone"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control contact-phone"
                                                            placeholder="Your Mobile No."
                                                        />
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="email">
                                                        <input
                                                            required
                                                            size="30"
                                                            type="email"
                                                            name="user_email"
                                                            id="ContactForm1_contact-form-email"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control contact-email"
                                                            placeholder="Your Email Address"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="single-row mt-2">
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="message">
                                                        <textarea
                                                            required
                                                            name="message"
                                                            cols="40"
                                                            rows="10"
                                                            class="wpcf7-form-control wpcf7-textarea form-control contact-message textarea1"
                                                            placeholder="Your requirement"                                                            
                                                            id="ContactForm1_contact-form-email-message"
                                                        >
                                                        </textarea>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="button-row">
                                                <div class="align-items-center">
                                                    <div class="text-center  d-flex justify-content-md-end justify-content-center subscribe-letsdiscuss-btn">
                                                        <button type="submit" id="ContactForm1_contact-form-submit" class=" btn btn-dark contact-us-btn">SUBMIT</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Section8