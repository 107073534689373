import React from 'react'
import "../../../Assets/Styles/NavSections/Services/ServicesSection1.css"
import { AiOutlinePhone } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'

const Heading = ({ title }) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate("/contactus")
    }
    return (
        <div class="d-flex flex-md-row flex-column align-item-center">           
            <div className='col-lg-8 col-md-8 col-12'>
                <h2 >{title}</h2>
            </div>
            <div className='col-lg-4 col-md-4 col-12'>
                <div class="breadcrumb gap-2" style={{marginTop:"100px"}} onClick={handleClick}>
                    <AiOutlinePhone style={{ color: "#fffff", marginRight: "8px", fontSize: "22px" }} />
                    <span>Contact Us</span>
                </div>
            </div>
        </div>
    )
}

export default Heading