import React from 'react'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import ReactjsSections1 from './ReactjsSections/ReactjsSections1'
import ReactjsSections2 from './ReactjsSections/ReactjsSections2'
import RNSections3 from '../ReactNativeHiring/RNSections.js/RNSections3'
import ReactjsSections3 from './ReactjsSections/ReactjsSections3'
import ReactjsSections4 from './ReactjsSections/ReactjsSections4'
import Section8 from '../../../Section8'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'
const ReactJs = () => {
    return (
        <>
            <Helmet>
                <title>We are the best go-to partner for hiring React JS developers | DLK React</title>
                <meta name="description" content="If you're looking for a React JS developer, DLK React is the perfect place to find one. We specialize in all things React and we can help you with any project!" />
            </Helmet>
            <RouteNav />
            <InsideNav />
            <ReactjsSections1 />
            <ReactjsSections2 />
            <RNSections3 />
            <ReactjsSections3 />
            <ReactjsSections4 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default ReactJs