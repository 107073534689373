import React from 'react'
import "../../../../../../Assets/Styles/NavSections/AboutSection4.css"
import { GiPayMoney } from "react-icons/gi"
import { AiOutlineComment, AiOutlineClockCircle, AiOutlineCode } from "react-icons/ai"
import { MdOutlineRocketLaunch } from "react-icons/md"
import { useState } from 'react'
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const RNSections4 = () => {
    const processData = [
        {
            heading: "Save Up to 50%",
            img: <GiPayMoney />,
            paragraph: "By hiring dedicated react native developers from DLK Technologies, clients can save up to 50% on their app development costs.",
            index: 1,
        },
        {
            heading: "Zero Communication Barrier",
            img: <AiOutlineComment />,
            paragraph: "Hire React Native developers from DLK Technologies and get a team of experts with good communication skills.",
            index: 2,
        },
        {
            heading: "Hire Experienced Developers",
            img: <AiOutlineClockCircle />,
            paragraph: "DLK Technologies hires experienced React Native developers to build mobile applications that are dynamic, cross-platform, or hybrid.",
            index: 3,
        },
        {
            heading: "Flexibility in Time Zones",
            img: <AiOutlineClockCircle />,
            paragraph: "We have experience working with clients from different time zones, whether they are in Europe, the US, the UK, Australia, or any other.",
            index: 4,
        },
        {
            heading: "Cost-effective Solution",
            img: <AiOutlineCode />,
            paragraph: "Providing our clients with a cost-effective solution that ensures uncompromised quality and on-time delivery, we form a win-win situation for all.",
            index: 5,
        },
        {
            heading: "On-Time Delivery",
            img: <MdOutlineRocketLaunch />,
            paragraph: "Over the past 10+ years, we have delivered 50+ projects on time every year. Our customer retention rate is 99%.",
            index: 6,
        }
    ]
    const [process, setProcess] = useState(processData)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="work-process-area overflow-hidden default-padding bottom-less">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Why Hire React Native Developers from DLK Technologies?"
                                    paragraph="Whether you are a startup, or medium-sized business, or an enterprise, we serve all sizes of our clients across the globe with our exceptional custom React Native App Development Services. Hire remote React Native Developers from React Ninjas that help you hit your goals faster by creating cross-platform mobile applications for iOS and Android."
                                />
                            </div>
                            <div class="container-full mt-5">
                                <div class="work-pro-items">
                                    <div class="row">
                                        {process.map((data) => {
                                            return (
                                                <div class="single-item col-lg-4 col-md-6">
                                                    <div class="item">
                                                        <div class="item-inner">
                                                            <div className='d-flex justify-content-center'>
                                                                <span class=" icon btn btn-circle btn-lg btn-soft-primary android-app-icon">
                                                                    <div className='imgProcess'> {data.img}</div>
                                                                </span>
                                                            </div>
                                                            <h5>{data.heading}</h5>
                                                            <p>{data.paragraph}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RNSections4