import React, { useState } from 'react'
import "../../Assets/Styles/Sections/Section11.css"
import reactjs from "../../Assets/Images/reactjs.png"
import sass from "../../Assets/Images/sass.png"
import bootstrap from "../../Assets/Images/bootstrap.png"
import rIcon from "../../Assets/Images/rIcon.png"
import fontaersome from "../../Assets/Images/fontaersome.png"
import rocket from "../../Assets/Images/rocket.svg"
import responsive from "../../Assets/Images/responsive.png"
import code from "../../Assets/Images/code.png"
import speed from "../../Assets/Images/speed.png"
import document from "../../Assets/Images/document.png"
import googlefont from "../../Assets/Images/google-font.png"
import antd from "../../Assets/Images/antd.png"

const Section11 = () => {
  const featuresData = [
    {
      label: "React",
      img: reactjs,
      index: 1
    },
    {
      label: "Sass",
      img: sass,
      index: 2
    },
    {
      label: "Bootstrap v5",
      img: bootstrap,
      index: 3
    },
    {
      label: "React Icons",
      img: rIcon,
      index: 4
    },
    {
      label: "Ant Design",
      img: antd,
      index: 5
    },
    {
      label: "Fontawesome Icon",
      img: fontaersome,
      index: 6
    },
    {
      label: "Fast Performance",
      img: rocket,
      index: 7
    },
    {
      label: "fully responsive",
      img: responsive,
      index: 8
    },
    {
      label: "Clean Code",
      img: code,
      index: 9
    },
    {
      label: "Speed Optimized",
      img: speed,
      index: 10
    },
    {
      label: "Well Documented",
      img: document,
      index: 11
    },
    {
      label: "Google Font",
      img: googlefont,
      index: 12
    },

  ]
  const [features, setFeatures] = useState(featuresData)
  return (
    <div id='features' className="more-features pt-90 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <div class="section__title-wrapper section__title-wrapper-4 mb-70 text-center mt-5">
              <h2 class="section__title">React Core Features
              </h2>
              <p>We Provide Impressive Freatures For your Website. You can easily manage Your website. You will love it.</p>
            </div>
          </div>
        </div>

        <div class="row">
          {features.map((data) => {
            return (
              <div class="col-lg-3 col-md-4 col-12 mb-30">
                <div class="bakix-features wow fadeInUp" >
                  <img src={data.img} alt="" />
                  <h4>{data.label}</h4>
                </div>
              </div>
            )
          })}        
        </div>
      </div>
    </div>
  )
}

export default Section11