import React from 'react'
import "../../../../../Assets/Styles/NavSections/Hiring/JsHiring.css"
import ourWorkBanner from "../../../../../Assets/Images/ourworkBanner.png"

const OurWorkSections3 = () => {
    return (
        <div className='choosing-plans'>
            <div className="iq-breadcrumb-info">
                <div className='container'>
                    <div className='row d-flex flex-row-reverse'>
                        <div className="col-lg-5 align-self-center">
                            <img src={ourWorkBanner} class="img-fluid" alt="image" width="auto" height="auto" />
                        </div>
                        <div class="col-lg-7 ourWorkTitle px-4">
                            <h2 class="iq-fw-8 mb-2 iq-mt-40">Perks of Choosing us for your React work</h2>
                            <ul class="listing-mark mt-1 two-col-listing">
                                <li>24/7 monitoring </li>
                                <li>Emergency support </li>
                                <li>Support requests</li>
                                <li>Rapid Issue Response</li>
                                <li>Dedicated Project Manager</li>
                                <li>Project Tracking System</li>
                                <li>QA Team</li>
                                <li>Alerts and Notifications</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurWorkSections3