import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import ReactSection2Card from '../../ReactSection2Card/ReactSection2Card'
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'


const RNSections2 = () => {
    const services = [
        {
            servicesHeading: "Hybrid App Development",
            servicesParagraph: "To craft customer-centric, scalable, compatible, and highly secure cross-platform applications, our remote React native app developers listen to your requirements and business objectives.",
            number: 1,
            index: 0
        },
        {
            servicesHeading: "API Integration",
            servicesParagraph: "In order to enhance the performance of your existing application and ensure that your customers have a memorable experience, DLK Technologies seamlessly integrates 3rd-party APIs such as payment integration, GPS, CRM, etc.",
            number: 2,
            index: 1
        },
        {
            servicesHeading: "Maintenance and Support",
            servicesParagraph: "DLK Technologies offers reliable maintenance and support services at every stage of the project and after deployment to ensure your application is running smoothly without bugs and has the latest features and functionalities.",
            number: 3,
            index: 2
        },

    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">                                
                                <HiringSectionTitle
                                    heading="Custom React Native App Development Services"
                                    paragraph="Our React Native app development services deliver pixel-perfect iOS & Android mobile apps that feel, perform, and look like native apps."
                                />
                                <ReactSection2Card
                                    serviceCard={serviceCard}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RNSections2