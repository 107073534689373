import React from 'react'
import "../../../../../../Assets/Styles/Sections/Section2.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import RNServices5 from "../../../../../../Assets/Images/RN-service5.png"

const MigrationServices3 = () => {
    return (
        <div id='about' className='ServiceSection5  custom-bottom-service-section overflow-hidden default-padding1'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self-center'>
                        <img src={RNServices5} class="img-fluid" alt="image" width="auto" height="auto" />
                    </div>
                    <div class="col-lg-6 col-md-6 align-self-center iq-choose-info">
                        <h2 class="iq-fw-8 mb-2 iq-mt-40">Why Choose DLK Technologies as Your ReactJS Migration Partner?</h2>
                        <p>Because we offer end-to-end migration services</p>
                        <ul class="listing-mark">
                            <li>Only experienced ReactJS developers with hands-on experience in the ReactJS technology stack are deployed.</li>
                            <li>To ensure a fast and secure application migration process, we align a "C" level of support with all clients.</li>
                            <li>For every project, we make milestones, tailor-made plans, and strictly adhere to deadlines to make sure the project is completed on time.</li>
                            <li>As a company with over 10 years of experience, we hold over 50% of the strength that is working from the very beginning, including software engineers, designers, QA, BA, and architects.</li>
                            <li>We have done it before and we will continue to do it every time. We assure 100% client satisfaction on ReactJS migration, design and development, deployment, maintenance, and support.</li>
                            <li>Our global clients trust us because we maintain 100% transparency. We only proceed when a non-disclosure agreement is signed that protects our clients' privacy.</li>
                        </ul>
                        <div class="cms-footer-btn">
                            <a class="b2b-btn" href="/hire/reactjs-developer">Hire React Developer</a>
                            <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect">Let's Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MigrationServices3