import React from 'react'
import RouteNav from '../RouteNav/RouteNav'
import InsideNav from '../RouteNav/InsideNav'
import OurWorkSections1 from './OurWorkSections/OurWorkSections1'
import OurWorkSections2 from './OurWorkSections/OurWorkSections2'
import OurWorkSections3 from './OurWorkSections/OurWorkSections3'
import Section8 from '../../Section8'
import FooterBottom from '../Aboutus/AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'
const OurWork = () => {
    return (
        <>
            <Helmet>
                <title>Showcasing the Best of DLK React | React JS</title>
                <meta name="description" content="Take a look at our impressive portfolio featuring top-notch projects and innovative solutions." />
            </Helmet>
            <RouteNav />
            <InsideNav />
            <OurWorkSections1 />
            <OurWorkSections2 />
            <OurWorkSections3 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default OurWork