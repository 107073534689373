import React from 'react'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import PluginSection1 from './PluginSections/PluginSection1'
import PluginSection2 from './PluginSections/PluginSection2'
import PluginSection3 from './PluginSections/PluginSection3'
import ServicesSection3 from '../ServicesSection/ServicesSection3'
import ServicesSection4 from '../ServicesSection/ServicesSection4'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import Section8 from '../../../Section8'
import { Helmet } from 'react-helmet'

const PluginDev = () => {
  return (
    <>
      <Helmet>
        <title>Best Reactjs Plugin Development Company in Chennai</title>
        <meta name="description" content="We are a top notch React JS Development company based in India. We at DLK React provide ultimate React JS Plugin development services with a cost-effective solution. We have highly skilled and experienced React Js developers. Contact us now." />
      </Helmet>
      <RouteNav />
      <InsideNav />
      <PluginSection1 />
      <PluginSection2 />
      <PluginSection3 />
      <ServicesSection3 />
      <ServicesSection4 />
      <Section8 />
      <FooterBottom />
    </>
  )
}

export default PluginDev