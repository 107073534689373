import React from 'react'
import "../../Assets/Styles/Sections/Section2.css"
import bannerBg2 from "../../Assets/Images/bannerBg2.png"
import aboutImg1 from "../../Assets/Images/aboutImg1.jpg"
import aboutImg2 from "../../Assets/Images/aboutImg2.jpeg"
import { FaPlay } from "react-icons/fa"
const Section2 = () => {
  return (
    <div id='about' className='about-standard-area overflow-hidden default-padding1'>
      <div className='fixed-shape'>
        <img src={bannerBg2} alt='Shape' />
      </div>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-6'>
            <div className='thumb'>
              <img src={aboutImg1} />
              <img src={aboutImg2} />
              <div className='overlay'>
                <div className='video'>
                  <a className='popup-youtube theme relative video-play-button'>
                    <i className='fa fa-play'>
                      <FaPlay />
                    </i>
                  </a>
                </div>
                <div class="content">
                  <h4>Founded in <strong>1964</strong></h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 info'>
            <h5>ABOUT US</h5>
            <h2>Achievement of DLK Technologies</h2>
            <p>
              We, DLK Technologies is the best React Website development company in chennai.
              We are a team of highly skilled and experienced professionals who are experts in
              <strong> React website development</strong>. We have worked on many projects and have successfully
              delivered them on time and within budget. We always put our client's needs and requirements
              first and work hard to deliver the best possible solution. Contact us today to get started
              on your next project!
            </p>
            <blockquote>
              We Provide Impressive Freatures For your Website. You can easily manage Your website. You will love it.
            </blockquote>
            <div className='col-12 d-flex justify-content-end'>
              <a onClick={() => window.open("https://dlktech.co.in/")} class=" btns btn-theme effect btn-md ">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section2