import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section4.css"
import ModalPage from '../Sections/ModalPage'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Section4 = () => {

    const offerData = [
        {
            heading: "React Web Development",
            paragraph: "Develop dynamic and creative web applications using React under the master group of advancement. It is the market leader for big-screen projects.",
            list1: "Easy to use",
            list2: "Speed & Flexibility",
            list3: "Flux architecture",
            index: 0
        },
        {
            heading: "Custom React Development",
            paragraph: "Build a custom website or application with the expert help of React improvements like Plugin Development, Integration, and Migration.",
            list1: "Easy to High compatibility",
            list2: "Easy to create Dynamic Web applications",
            list3: "Reusable and customizable components",
            index: 1
        },
        {
            heading: "React Native App Development",
            paragraph: "We have a team of React developers who can help you migrate your website or mobile app handily and quickly.",
            list1: "Fast applications",
            list2: "SEO User friendly",
            list3: "Large developer community",
            index: 2
        },
        {
            heading: "Maintenance & Support",
            paragraph: "Our applications deliver a powerful, feature-rich digital experience to users in a variety of industries.",
            list1: "Structured Packages",
            list2: "Bug Fixes",
            list3: "Easily upgradable",
            index: 3
        },
    ]

    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [offers, setOffers] = useState(offerData)
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };

    return (
        <div id='services' className='iq-tab services-tab what-do-offer'>
            <div class="container">
                <div class="row services-offers">
                    <div class="col-md-5 position-sticky">
                        <div class="section-title">
                            <p class="iq-rmt-40 text-uppercase  iq-fw-3 iq-ls-3 mb-1 headerWithTitle">Best Ever Services</p>
                            <h2 class="title iq-fw-8 mt-3">What do we offer?</h2>
                            <p class="mt-4 mb-3 offerPara ">
                                DLK Technologies is a renowned name for offering world-class
                                React Website development services. We have a team of highly skilled and experienced React
                                developers who are experts in developing cutting-edge websites that are not only visually appealing
                                but also deliver superior performance. With us, you can be assured of getting a website that is
                                tailor-made to meet your specific business requirements and goals. So, if you are looking for a
                                reliable partner for your React Web development needs,
                                then DLK Technologies is the right choice for you.
                            </p>
                            <div class="cms-banner-btn">
                                <a class="m-store" href='/hire/reactjs-developer'>Hire React Developer</a>
                                <a href="/contactus">Contact us</a>
                            </div>
                            <ModalPage
                                onFinishFailed={onFinishFailed}
                                onFinish={onFinish}
                                handleCancel={handleCancel}
                                showModal={showModal}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                sendEmail={sendEmail}
                                form={form}
                            />
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="container tab-content">
                            {offers.map((data) => {
                                return (
                                    <div class="container main-content">
                                        <div class="row justify-content-between">
                                            <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                                                <h2 class="title iq-fw-8">{data.heading}</h2>
                                                <p className='offerPara'>{data.paragraph}</p>
                                                <ul class="listing-mark mt-4">
                                                    <li>{data.list1}</li>
                                                    <li>{data.list2}</li>
                                                    <li>{data.list3}</li>
                                                </ul>
                                                <a onClick={showModal} data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4