import React, { createRef, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
// import "../../Assests/Styles/ModalPage/ModalPage.css"
// import { Input, Modal } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import "../../Assets/Styles/NavSections/Hiring/JsHiring.css"
import "../../Assets/Styles/NavSections/Contactus/Contactus.css"
import { GrMail } from "react-icons/gr"
import { FaPhoneAlt } from "react-icons/fa"
import HiringForm from './NavSections/ReactHiring/HiringForm/HiringForm';
// const { TextArea } = Input;

const ModalPage = ({ handleCancel, isModalOpen }) => {
    return (
        <div>
            <Modal show={isModalOpen} onHide={handleCancel} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='iq-contact-us inner-content'>
                        <div className='container'>
                            <div className='row flex-row-reverse'>
                                <div className='col-sm-12 col-lg-6 col-xl-6'>
                                    <HiringForm />
                                </div>
                                <div class="col-sm-12 col-lg-6 mb-5">
                                    <div class="left-content1">
                                        <h3>Let's meet &amp; Discuss Work</h3>
                                        <div class="contact-info-company">
                                            <ul>
                                                <li>10+ years experience in React</li>
                                                <li>Certified React team</li>
                                                <li>955+ React Projects</li>
                                                <li>Clean React code</li>
                                                <li>24/7 emergency support</li>
                                            </ul>
                                        </div>
                                        <div class="contact-info">
                                            <div class="media">
                                                <div class="contact-icon"></div>
                                                <div class="media-body">
                                                    <h3>Message us</h3>
                                                    <p class="iq-fw-5">
                                                        <i class="fas fa-envelope ">
                                                            <GrMail />
                                                        </i>
                                                        <a title="mailus">info@dlktech.co.in</a>
                                                    </p>
                                                    <p class="iq-fw-5">
                                                        <i class="fas fa-envelope ">
                                                            <FaPhoneAlt style={{ color: "white" }} />
                                                        </i>
                                                        <a title="mailus">+91 7299951536</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalPage