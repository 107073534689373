import React from 'react'
import "../../../../../Assets/Styles/NavSections/AboutSection5.css"
import bannerbg6 from "../../../../../Assets/Images/bannerBg6.png"

const AboutSection5 = () => {
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="works-about-area overflow-hidden">
                            <div class="container">
                                <div class="works-about-items default-padding">
                                    <div class="row align-center">
                                        <div class="col-lg-6 col-md-6 info">
                                            <h5>Works About</h5>
                                            <h2>Perks of DLK Technologies</h2>                                           
                                            <ul class="listing-mark mt-1">
                                                <li style={{ paddingLeft: "35px" }}> Smart update administrations rates</li>
                                                <li style={{ paddingLeft: "35px" }}> Exact quality assurance test before delivery</li>
                                                <li style={{ paddingLeft: "35px" }}> Only the senior engineers' group will handle customers' tasks</li>
                                                <li style={{ paddingLeft: "35px" }}> Relentless investigation of Security and Upgrade highlights.</li>
                                                <li style={{ paddingLeft: "35px" }}> Skill at accomplishing objectives</li>
                                                <li style={{ paddingLeft: "35px" }}> In order to convey faultless progress to the refreshed rendition, the interaction must be broken down consistently</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="col-md-12 thumb">
                                                <img src={bannerbg6} alt="Thumb" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection5