import React, { useState } from 'react'
import HiringSectionTitle from '../../../../Reusable/HiringSectionTitle/HiringSectionTitle'
import "../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import work1 from "../../../../../Assets/Images/work1.png"
import work2 from "../../../../../Assets/Images/work2.png"
import work3 from "../../../../../Assets/Images/work3.png"
import work4 from "../../../../../Assets/Images/work4.png"
import work5 from "../../../../../Assets/Images/work5.png"
import work6 from "../../../../../Assets/Images/work6.png"
import work7 from "../../../../../Assets/Images/work7.png"
import work8 from "../../../../../Assets/Images/work8.png"
import work9 from "../../../../../Assets/Images/work9.png"
import work10 from "../../../../../Assets/Images/work10.png"
import work11 from "../../../../../Assets/Images/work11.png"
import work12 from "../../../../../Assets/Images/work12.png"
import { imgWork1, imgWork2, imgWork3, imgWork4, imgWork5, imgWork6, imgWork7, imgWork8, imgWork9, imgWork10, imgWork11, imgWork12 } from "./ImageData/ImageData"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Button, Modal } from 'antd';
import Modal from 'react-bootstrap/Modal';

const OurWorkSections2 = () => {
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1
        }
    };
    const workData = [
        {
            img: work1,
            label: "E-Learning Portal",
            open: setOpen1,
            index: 1
        },
        {
            img: work2,
            label: "Digital Marketing Agency",
            index: 2,
            open: setOpen2,
        },
        {
            img: work3,
            label: "Real Estate Portal",
            open: setOpen3,
            index: 3
        },
        {
            img: work4,
            label: "Fashion Beauty Portal",
            open: setOpen4,
            index: 4
        },
        {
            img: work5,
            label: "Online Food Ordering",
            open: setOpen5,
            index: 5
        },
        {
            img: work6,
            label: "Healthcare Portal",
            open: setOpen6,
            index: 6
        },
        {
            img: work7,
            label: "Car Washing Portal",
            open: setOpen7,
            index: 7
        },
        {
            img: work8,
            label: "College Design",
            open: setOpen8,
            index: 8
        },
        {
            img: work9,
            label: "Hotel Booking Design",
            open: setOpen9,
            index: 9
        },
        {
            img: work10,
            label: "Digital Marketing for Company",
            open: setOpen10,
            index: 10
        },
        {
            img: work11,
            label: "Airline Website",
            open: setOpen11,
            index: 11
        },
        {
            img: work12,
            label: "Advanced Java Traning",
            open: setOpen12,
            index: 12
        },
    ]
    const [work, ourWork] = useState(workData)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Latest Projects"
                                />
                                <div className='row'>
                                    {work.map((data) => {
                                        return (
                                            <div className='col-lg-4 col-md-12 mt-4 mainWorkData'>
                                                <div className='item workImage' onClick={() => data.open(true)}>
                                                    <a className='' style={{ cursor: "pointer" }}>
                                                        <img width="400" height="282" src={data.img} />
                                                    </a>
                                                    <div className='col-12 project-details d-flex justify-content-center'>
                                                        <h2><a >{data.label}</a></h2>
                                                    </div>
                                                </div>
                                                <Modal
                                                    title="E-Learning Portal"
                                                    centered
                                                    show={open1}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen1(false)}
                                                    size='lg'
                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork1.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>

                                                </Modal>

                                                <Modal
                                                    title="Digital Marketing Agency"
                                                    centered
                                                    show={open2}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen2(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork2.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Real Estate Portal"
                                                    centered
                                                    show={open3}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen3(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork3.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Fashion Beauty Portal"
                                                    centered
                                                    show={open4}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen4(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork4.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Online Food Ordering"
                                                    centered
                                                    show={open5}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen5(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork5.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Healthcare Portal"
                                                    centered
                                                    show={open6}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen6(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork6.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Car Washing Portal"
                                                    centered
                                                    show={open7}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen7(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork7.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="College Design"
                                                    centered
                                                    show={open8}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen8(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork8.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Hotel Booking Design"
                                                    centered
                                                    show={open9}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen9(false)}
                                                    size='lg'

                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork9.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>


                                                <Modal
                                                    title="Hotel Booking Design"
                                                    centered
                                                    show={open10}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen10(false)}
                                                    size='lg'
                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork10.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Hotel Booking Design"
                                                    centered
                                                    show={open11}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen11(false)}
                                                    size='lg'
                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork11.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>

                                                <Modal
                                                    title="Advanced Java Training"
                                                    centered
                                                    show={open12}
                                                    // onOk={() => setOpen(false)}
                                                    onHide={() => setOpen12(false)}
                                                    size='lg'
                                                >
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Carousel
                                                        autoPlay={true}
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        responsive={responsive}
                                                        autoPlaySpeed={3000}
                                                        transitionDuration={500}
                                                        showDots={false}
                                                        dotListClass="custom-dot-list-style"
                                                        className='mb-5 px-3'
                                                    >
                                                        {imgWork12.map((data) => {
                                                            return (
                                                                <img src={data.img} />
                                                            )
                                                        })}
                                                    </Carousel>
                                                </Modal>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurWorkSections2