import React from 'react'
import "../../Assets/Styles/Sections/Section9.css"
import { FiSend, FiPhoneCall } from "react-icons/fi"
import { GoLocation, GoMailRead } from "react-icons/go"
import logo from "../../Assets/Images/logos.png"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import contact from "../../Assets/Images/contact.gif"

const Section9 = () => {
    return (
        <footer id='contact' class="bg-dark1 text-light footer1" style={{marginBottom:"30px"}}>
            <div className='col-12 d-flex justify-content-end'>
                <img src={contact} width={60} style={{margin:"20px"}}/>
            </div>
            <div class="fixed-shape">
                <img src="https://wordpressriverthemes.com/tanda/wp-content/themes/tanda/img/map.svg" alt="Shape" />
            </div>
            <div class="container">
                <div class="f-items">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 item">
                            <div class="f-item about">
                                <img src={logo} class="img-fluid footerlogo" width="200" />
                                <p>
                                    DLK Technologies Pvt. Ltd has been a topmost web designing and development with best digital marketing company in India.                                    </p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 item">
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Company</h4>
                                <div class="menu-company-container">
                                    <ul id="menu-company" class="menu">
                                        <Link to="about" smooth={true} duration={100}>
                                            <li id="menu-item-312" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-312">
                                                <a>About Us</a>
                                            </li>
                                        </Link>
                                        <Link to="services" smooth={true} duration={100}>
                                            <li id="menu-item-314" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-314">
                                                <a>Our Services</a>
                                            </li>
                                        </Link>
                                        <Link to="features" smooth={true} duration={100}>
                                            <li id="menu-item-317" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-317">
                                                <a>Our Features</a>
                                            </li>
                                        </Link>
                                        <Link to="ourwork" smooth={true} duration={100}>
                                            <li id="menu-item-313" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-313">
                                                <a>Our Work</a>
                                            </li>
                                        </Link>
                                        <Link to="Testimonials" smooth={true} duration={100}>
                                            <li id="menu-item-315" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-315">
                                                <a>Testimonials</a>
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 item">
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Help</h4>
                                <div class="menu-solutions-container">
                                    <ul id="menu-solutions" class="menu">
                                        <li id="menu-item-319" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-319">
                                            <a onClick={() => window.open("https://dlktech.co.in/faq/")}>FAQ</a>
                                        </li>
                                        <li id="menu-item-321" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-321">
                                            <a onClick={() => window.open("https://dlktech.co.in/terms-and-conditions/")}>Terms & Conditions</a>
                                        </li>
                                        <li id="menu-item-320" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-320">
                                            <a onClick={() => window.open("https://dlktech.co.in/privacy-policy/")}>Privacy Policy</a>
                                        </li>
                                        <li id="menu-item-416" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-416">
                                            <a onClick={() => window.open("https://dlktech.co.in/refund-policy/")}>Refund Policy</a>
                                        </li>
                                        <li id="menu-item-419" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-419">
                                            <a onClick={() => window.open("https://dlktech.co.in/technologies/")}>Technologies</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 item">
                            <div class="f-item">
                                <h4 class="widget-title px-4">Contact Info</h4>
                                <div class="address">
                                    <ul>
                                        <li className='d-flex flex-row gap-3'>
                                            <div class="icon">
                                                <i class="flaticon-pin">
                                                    <GoLocation className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                Door No : 68 & 70 , No : 174, Ground Floor , Rahaat Plaza (Opp. of Vijaya Hospital), Vadapalani ,Chennai, Tamil Nadu 600026.
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-3'>
                                            <div class="icon">
                                                <i class="flaticon-email-1">
                                                    <GoMailRead className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                <a href="">info@dlktech.co.in</a>
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-3'>
                                            <div class="icon">
                                                <i class="flaticon-telephone">
                                                    <FiPhoneCall className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                +91-7299951536 <br /> +91-9751800789 </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <p>© 2023. Design & Developed by DLK Technologies Pvt Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Section9