import React, { useState, useRef } from 'react'
import "../../../../Assets/Styles/Navbar/Navbar.css"
import logo from "../../../../Assets/Images/logos.png"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { FaPhone } from "react-icons/fa"
import ModalPage from '../../ModalPage';
import { Link } from 'react-router-dom';

const InsideNav = () => {
    // let navigate = useNavigate()
    const [navbar, setNavbar] = useState(false)
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };

    const changeBackground = () => {
        if (window.scrollY >= 90) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <div id="navbar" className={navbar ? 'navbar-area is-stickyInsideNav fixed-top  insideNav ' : 'navbar-area insideNav fixed-top  mt-6'}>
            <div className='container main-navbar mt-2'>
                <div className=''>
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="/">
                            <img src={logo} class="img-fluid" width="200" />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto">
                                <li class="insideLink">
                                    <a class="nav-link " aria-current="page" href="/">Home</a>
                                </li>
                                <li class="insideLink">
                                    <a class="nav-link " aria-current="page" href="/aboutus">About Us</a>
                                </li>
                                <li class="insideLink dropdown">
                                    <a class="nav-link" href="/services/react-native-app-development" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Services
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <Link class="dropdown-item" to="/services/react-native-app-development" >React Native App <br /> Development</Link>
                                        <Link class="dropdown-item" to="/services/reactjs-plugin-development">React Js Plugin <br /> Development</Link>
                                        <Link class="dropdown-item" to="/services/reactjs-migration-services">React Js Migration Services</Link>
                                        <Link class="dropdown-item" to="/services/react-support-services-maintenance">React Support & <br /> Maintenance Services</Link>
                                        <Link class="dropdown-item" to="/services/reactjs-integration-services">React Js Integration <br /> Services</Link>
                                    </div>
                                </li>
                                <li class="insideLink dropdown">
                                    <a class="nav-link " aria-current="page" href="/hire/reactnative-app-developer" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Hiring React Developer</a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <Link class="dropdown-item" to="/hire/reactnative-app-developer" >Hire React Native App <br /> Developer</Link>
                                        <Link class="dropdown-item mt-2" to="/hire/reactjs-developer">Hire React Js <br /> Developer</Link>
                                    </div>
                                </li>
                                <li class="insideLink">
                                    <a class="nav-link insideLink" aria-current="page" href="/ourwork">Our Work</a>
                                </li>
                                <li class="insideLink">
                                    <a class="nav-link insideLink" aria-current="page" href="/contactus">ContactUs</a>
                                </li>
                            </ul>
                        </div>
                        <div class="others-options d-md-flex align-items-center d-none">
                            <div className='option-item'>
                                <ul>
                                    <li className='insidecontact'>
                                        <i className='flaticon-telephone'><FaPhone style={{ color: "#086AD8" }} /></i>
                                        <p className='mt-2' onClick={showModal}>Call us today! <br /> <strong>+91 7299951536</strong></p>
                                    </li>
                                </ul>
                                {/* <a class="default-btn" onClick={showModal}>CONTACT US</a> */}
                            </div>
                        </div>
                        <ModalPage
                            onFinishFailed={onFinishFailed}
                            onFinish={onFinish}
                            handleCancel={handleCancel}
                            showModal={showModal}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            sendEmail={sendEmail}
                            form={form}
                        />
                    </nav>
                </div>
            </div>
        </div >
    )
}

export default InsideNav