import React, { useState } from 'react'
import "../../Assets/Styles/Sections/Section3.css"
import bannerBg3 from "../../Assets/Images/bannerbg3.png"

const Section3 = () => {
    const workData = [
        {
            label: "Analyzing",
            number: "1",
            paragraph: "We will analyze your project requirements and find the perfect solution for your website."
        },
        {
            label: "Development",
            number: "2",
            paragraph: "Upon analysis, we will begin the developing process of the site under certified developers and team for delivering the promising results to the client."
        },
        {
            label: "Go Live",
            number: "3",
            paragraph: " After receiving your feedback, we work on them and make the site go live. Our tech team is available to provide assistance should you wish to go live from your end."
        },
    ]
    const [works, setwork] = useState(workData)
    return (
        <div className='standard-services-area bg-gray carousel-shadow default-padding2'>
            <div className='shape d-md-flex d-none'>
                <img src={bannerBg3} />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='site-heading text-center'>
                        <h2>How does ReactJS Work?</h2>
                        <p className='mt-4'>
                            We have a team of highly skilled and experienced React developers
                            who can create amazing websites and applications as per your specific requirements:
                        </p>
                    </div>
                    <div className='mt-5 row gx-lg-8 gx-xl-12 gy-6 process-wrapper line'>
                        {works.map((data) => {
                            return (
                                <div className='col-md-4 col-lg-4'>
                                    <span class="icon btn btn-circle btn-lg btn-soft-primary home-sec-icon d-md-flex d-none">
                                        <span class="number">{data.number}</span>
                                    </span>
                                    <h4 className='listed'>{data.label}</h4>
                                    <p></p>
                                    <p className='reactAnalysis'>
                                        {data.paragraph}
                                    </p>
                                    <p></p>
                                </div>
                            )
                        })}                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section3