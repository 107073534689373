import React from 'react'
import "../../../../../../Assets/Styles/Sections/Section2.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import RNServices2 from "../../../../../../Assets/Images/RN-service2-3.png"

const PluginSection3 = () => {
    return (
        <div id='about' className='ServiceSection5  custom-bottom-service-section overflow-hidden default-padding1'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self-center'>
                        <img src={RNServices2} class="img-fluid" alt="image" width="auto" height="auto" />
                    </div>
                    <div className='col-lg-6 col-md-6 align-self-center iq-choose-info'>
                        <h2 class="iq-fw-81 mb-2 iq-mt-40">Prime Benefits of the ReactJS Framework</h2>
                        <ul class="listing-mark">
                            <li>Easy to learn</li>
                            <li>Rich user interfaces can be built with this tool</li>
                            <li>Provides the ability to write custom components</li>
                            <li>High Scalability &amp; High Performance</li>
                            <li>Code of fine delicacy</li>
                            <li>Reusable Codes &amp; Components</li>
                            <li>Adept Data Binding</li>
                            <li>SEO-Friendly &amp; User-friendly</li>
                            <li>Out-of-the-box Developer Tools</li>
                        </ul>
                        <div class="cms-footer-btn">
                            <a class="b2b-btn" href="/hire/reactjs-developer">Hire React Developer</a>
                            <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let's Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PluginSection3