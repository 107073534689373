import React, { useState, useRef } from 'react'
import Navbar from '../Navbar/Navbar'
import Section1 from '../Sections/Section1'
import Section10 from '../Sections/Section10'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
import Section6 from '../Sections/Section6'
import Section7 from '../Sections/Section7'
import Section8 from '../Sections/Section8'
import Section9 from '../Sections/Section9'
import Section11 from '../Sections/Section11'
import { Helmet } from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import inr from "../../Assets/Images/inr.png"
import ModalPage from '../Sections/ModalPage'
import "../../Assets/Styles/LandingPage/LandingPage.css"
const LandingPage = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div>
            <Helmet>
                <title>Best React Development Company</title>
                <meta name="description" content="DLK React is the Best React Development Company, with tools and experts to create custom solutions for you." />
            </Helmet>
            <section>
                <Navbar />
            </section>
            <section>
                <Section1 />
            </section>
            <section>
                <Section2 />
            </section>
            <section>
                <Section3 />
            </section>
            <section >
                <Section4 />
            </section>
            <section >
                <Section10 />
            </section>
            <section >
                <Section11 />
            </section>
            <section >
                <Section5 />
            </section>
            <section >
                <Section6 />
            </section>
            <section >
                <Section7 />
            </section>
            <section >
                <Section8 />
            </section>
            <section >
                <Section9 />
            </section>
            <section class="footer-sticky hidden-xs hidden-sm d-flex flex-row " >
                <div class="col-lg-7 center_bx_foot">
                    <div class="num_card">
                        <span class="text">For Career Assistance :</span>
                        <span class="in_num">
                            <img src={inr} alt="flag_in" />
                            <a aria-label="India flag" href="tel:+91 7299951536 "> +91-7299951536 </a>
                        </span>
                    </div>
                </div>
                <div class="col-lg-3 center_bx_foot mt-1" style={{ color: "white" }} onClick={showModal}>
                    <button className='insideNav-Link sendEnquiry'>Send Your Enquiry</button>
                </div>
                <ModalPage
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    handleCancel={handleCancel}
                    showModal={showModal}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    sendEmail={sendEmail}
                    form={form}
                />
            </section>
        </div>
    )
}

export default LandingPage