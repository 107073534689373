import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section6.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import ModalPage from './ModalPage';

const Section6 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()
  const form = useRef();
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleClick = () => {
    navigate("/contactus")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs.sendForm(
      'service_z2h60aw',
      'template_mxq95am',
      form.current,
      'ymjMeKsCwpSffaKHA'
    )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully! We will get back to you soon.");
        e.target.reset();
        console.log("message sent");
      }, (error) => {
        toast.error("Message Sending Failed! Please try again.")
        console.log(error.text);
      });
  };
  return (
    <div className='wrapper wrapper-border section6withcontent'>
      <div class="container py-14 py-md-18">
        <div class="row pt-5 mb-5">
          <div class="col-lg-6 order-lg-2">
            <h3 class="title iq-fw-8 mb-3">SKILLS</h3>
            <div class="progress-bar-home">
              <div class="progress-bar-main">
                <div class="progress-content">
                  <div class="progress-inner-content">
                    <h6>React JS Development</h6>
                  </div>
                  <div class="progress-percentage">
                    <h5>91%</h5>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" style={{ width: " 91%" }} aria-valuenow="91" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="progress-bar-main">
                <div class="progress-content">
                  <div class="progress-inner-content">
                    <h6>React Native Development</h6>
                  </div>
                  <div class="progress-percentage">
                    <h5>87%</h5>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar" style={{ width: "87%" }} aria-valuenow="87" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="progress-bar-main">
                <div class="progress-content">
                  <div class="progress-inner-content">
                    <h6>React API Development</h6>
                  </div>
                  <div class="progress-percentage">
                    <h5>85%</h5>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-warning" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="progress-bar-main">
                <div class="progress-content">
                  <div class="progress-inner-content">
                    <h6>React Troubleshooting Service</h6>
                  </div>
                  <div class="progress-percentage">
                    <h5>93%</h5>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-danger" role="progressbar" style={{ width: "93%" }} aria-valuenow="93" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-md-0 mt-4">
            <h2 class="title iq-fw-8 mb-3">Technical Expertise</h2>
            <p class="fs-20 mb-4 offerPara ">For all your business needs, we have a professional React development team with years of experience and technical expertise.</p>
            <div class="btn-standard filled">
              <a href="javascript:void(0)" data-toggle="modal" data-target="#GetaQuoteModal" onClick={showModal}>Get A Quote</a>
              <a class="what-we" href="/contactus">Contact us</a>
              <ModalPage
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                handleCancel={handleCancel}
                showModal={showModal}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                sendEmail={sendEmail}
                form={form}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <ul class="listing-mark">
            </ul>
          </div>
          <div class="col-md-6 col-lg-3">
            <ul class="listing-mark">
            </ul>
          </div>
          <div class="col-md-6 col-lg-3">
            <ul class="listing-mark">
            </ul>
          </div>
          <div class="col-md-6 col-lg-3">
            <ul class="listing-mark">
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section6