import React, { useState } from 'react'
import "../../Assets/Styles/Sections/Section5.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import work1 from "../../Assets/Images/work1.png"
import work2 from "../../Assets/Images/work2.png"
import work3 from "../../Assets/Images/work3.png"
import work4 from "../../Assets/Images/work4.png"
import work5 from "../../Assets/Images/work5.png"
import work6 from "../../Assets/Images/work6.png"
import work7 from "../../Assets/Images/work7.png"
import work8 from "../../Assets/Images/work8.png"
import work9 from "../../Assets/Images/work9.png"
import work10 from "../../Assets/Images/work10.png"
import work11 from "../../Assets/Images/work11.png"
import work12 from "../../Assets/Images/work12.png"

const Section5 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };
  const workData = [
    {
      img: work1,
      label: "E-Learning Portal",
      // open: setOpen1,
      index: 1
    },
    {
      img: work2,
      label: "Digital Marketing Agency",
      index: 2,
      // // open: setOpen2,
    },
    {
      img: work3,
      label: "Real Estate Portal",
      // // open: setOpen3,
      index: 3
    },
    {
      img: work4,
      label: "Fashion Beauty Portal",
      // // open: setOpen4,
      index: 4
    },
    {
      img: work5,
      label: "Online Food Ordering",
      // // open: setOpen5,
      index: 5
    },
    {
      img: work6,
      label: "Healthcare Portal",
      // // open: setOpen6,
      index: 6
    },
    {
      img: work7,
      label: "Car Washing Portal",
      // // open: setOpen7,
      index: 7
    },
    {
      img: work8,
      label: "College Design",
      // // open: setOpen8,
      index: 8
    },
    {
      img: work9,
      label: "Hotel Booking Design",
      // // open: setOpen9,
      index: 9
    },
    {
      img: work10,
      label: "Digital Marketing for Company",
      // // open: setOpen10,
      index: 10
    },
    {
      img: work11,
      label: "Airline Website",
      // // open: setOpen11,
      index: 11
    },
    {
      img: work12,
      label: "Advanced Java Traning",
      // // open: setOpen12,
      index: 12
    },
  ]
  const [work, ourWork] = useState(workData)
  return (
    <div id='ourwork' className='standard-services-area bg-gray carousel-shadow default-padding'>
      <div className='container'>
        <div className='row'>
          <div className='site-heading text-center'>
            <h2>Lastest Projects</h2>
          </div>
          <div className='mt-5 px-5'>
            <Carousel
              autoPlay={true}
              // removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
              responsive={responsive}
              autoPlaySpeed={3000}
              transitionDuration={500}
              showDots={false}
              dotListClass="custom-dot-list-style"
            >
              {work.map((data) => {
                return (
                  <div class="owl-item active">
                    <div class="item">
                      <figure class="rounded mb-7">
                        <a>
                          <img src={data.img} alt="" />
                        </a>
                      </figure>
                      <div class="project-details d-flex justify-content-center flex-column">
                        <div class="post-header d-flex justify-content-center">
                          <h2 class="post-title h3">
                            <a class="link-dark">{data.label}</a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}                           
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section5