import React from 'react'
import "../../../../../Assets/Styles/NavSections/Hiring/JsHiring.css"
import "../../../../../Assets/Styles/NavSections/Contactus/Contactus.css"
import HiringForm from '../../ReactHiring/HiringForm/HiringForm'
import { GrMail } from "react-icons/gr"
import { FaPhone  } from "react-icons/fa"

const ContactusSection = () => {
    return (
        <>
            <div className='iq-breadcrumb contact-banner'>
                <div className="iq-breadcrumb-info">
                    <div className='container'>
                        <div className='col-12 contactUS'>
                            <div className='col-12 d-flex justify-content-center'>
                                <h2 class="iq-fw-8 mb-3">Contact Us</h2>
                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                <p>We are here to answer any question you may have.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='iq-contact-us inner-content'>
                <div className='container'>
                    <div className='row flex-row-reverse pt-5'>
                        <div className='col-sm-12 col-lg-6 col-xl-6'>
                            <HiringForm />
                        </div>
                        <div class="col-sm-12 col-lg-6 mb-5">
                            <div class="left-content">
                                <h3>Let's meet &amp; Discuss Work</h3>
                                <div class="contact-info-company">
                                    <ul>
                                        <li>10+ years experience in React</li>
                                        <li>Certified React team</li>
                                        <li>Special Unit for React</li>
                                        <li>955+ React Projects</li>
                                        <li>Clean React code</li>
                                        <li>24/7 emergency support</li>
                                    </ul>
                                </div>
                                <div class="contact-info">
                                    <div class="media">
                                        <div class="contact-icon"></div>
                                        <div class="media-body">
                                            <h3>Contact us</h3>
                                            <p class="iq-fw-5"><i class="fas fa-envelope ">
                                                <GrMail />
                                            </i><a title="mailus">info@dlktech.co.in</a></p>  
                                            <p class="iq-fw-5"><i class="fas fa-envelope ">
                                                <FaPhone  />
                                            </i><a title="mailus">+91 7299951536</a></p>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactusSection