import React from 'react'
import "../../Assets/Styles/Sections/Section10.css"
import smallbanner from "../../Assets/Images/smallbanner.jpg"
const Section10 = () => {
    return (
        <div className='cta__area cta__padding cta__overlay include-bg' >
            {/* <img src={smallbanner} className="imgbanner"/> */}
            <div className='container'>
                <div class="row">
                    <div class="col-xxl-12">
                        <div class="cta__content">
                            <h3 class="cta__title">Don't worry about code. Just Create !</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section10