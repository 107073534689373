import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import { BsLayoutTextWindowReverse } from "react-icons/bs"
import { AiOutlineDashboard } from "react-icons/ai"
import { MdOutlineAppRegistration, MdOutlineLocalShipping } from "react-icons/md"
import { VscExtensions } from "react-icons/vsc"
import { TbHandClick } from "react-icons/tb"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const IntegrationServices2 = () => {
    const services = [
        {
            servicesHeading: "Custom Solutions",
            servicesParagraph: "Due to our belief that one solution does not fit all, we offer custom solutions for every unique requirement and design a new roadmap based on the client's needs.",
            img: <AiOutlineDashboard />,
            index: 0
        },
        {
            servicesHeading: "Result-Driven Work Methodology",
            servicesParagraph: "A result-oriented work methodology has been developed by our experts with years of experience, allowing them to focus on delivering only the best products and services.",
            img: <MdOutlineAppRegistration />,
            index: 1
        },
        {
            servicesHeading: "Innovative & Unique Approach",
            servicesParagraph: "Our approach is unique with the motto of helping clients achieve their objectives. We are passionate about learning and implementing the best technologies that will better suit your requirements.",
            img: <BsLayoutTextWindowReverse />,
            index: 2
        },
        {
            servicesHeading: "On-Time Project Deployment",
            servicesParagraph: "With a commitment to delivering the project within the given timeframe, we follow the result-driven development process and apply best practices like Continuous Integration & Continuous Deployment.",
            img: < MdOutlineLocalShipping />,
            index: 3
        },
        {
            servicesHeading: "Trustworthy & Reliable Support",
            servicesParagraph: "Every project we onboard receives constant, 24/7 and C-level support, ensuring it meets the latest market standards and performs seamlessly over its entire lifespan.",
            img: <VscExtensions />,
            index: 4
        },
        {
            servicesHeading: "Constant Communication",
            servicesParagraph: "DLK Technologies provides a C level of support to our clients, where the senior tech lead oversees the project and ensures constant communication between the developer and client.",
            img: <TbHandClick />,
            index: 5
        }
    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Why Choose Us as Your ReactJS Integration Partner?"
                                    paragraph="Whether it is to realign the business application as per the need, enhance the performance of the web and application, or provide advanced features and functionalities to the end-user DLK Technologies provide end to end integration services. We follow only the best practices of ReactJS integration services ensuring a seamless data exchange, and bug-free integration and enabling you to reap optimum benefit from this most needed integration service."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-icons layout-ic">
                                                            <i class="icomoon icon-layout">
                                                                <div className='service-icons'>
                                                                    {data.img}
                                                                </div>
                                                            </i>
                                                        </div>
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8 mb-2">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY">{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntegrationServices2