import React from 'react'
import "../../../../../../Assets/Styles/NavSections/AboutSection4.css"
import { GiPayMoney } from "react-icons/gi"
import { AiOutlineComment, AiOutlineClockCircle, AiOutlineCode } from "react-icons/ai"
import { MdOutlineRocketLaunch } from "react-icons/md"
import { useState } from 'react'
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const ReactjsSections3 = () => {
    const processData = [
        {
            heading: "Save Up to 50%",
            img: <GiPayMoney />,
            paragraph: "DLK Technologies offers dedicated React JS developers that can save you up to 50% on your React JS application development costs.",
            index: 1,
        },
        {
            heading: "On-time Delivery",
            img: <AiOutlineComment />,
            paragraph: "With our offshore React JS developers, we guarantee on-time delivery, enabling quick-to-market solutions.",
            index: 2,
        },
        {
            heading: "Transparency & Integrity",
            img: <AiOutlineClockCircle />,
            paragraph: "While working on your project, we encourage our developers to sign NDAs and follow privacy rules.",
            index: 3,
        },
        {
            heading: "Easy Migration",
            img: <AiOutlineClockCircle />,
            paragraph: "Hire our remote React JS developers for seamless, smooth, and secure migrations.",
            index: 4,
        },
        {
            heading: "24/7 Availability",
            img: <AiOutlineCode />,
            paragraph: "React JS developers should stay connected through various communication channels to ensure prompt delivery.",
            index: 5,
        },
        {
            heading: "Cost Effective",
            img: <MdOutlineRocketLaunch />,
            paragraph: "You can save up to 50% on development, maintenance, and support costs with our hiring modules.",
            index: 6,
        }
    ]
    const [process, setProcess] = useState(processData)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="work-process-area overflow-hidden default-padding bottom-less">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Why Hire React JS Developers from DLK Technologies?"
                                    paragraph="DLK Technologies have developed some of the most complex projects using React JS. We provide highly motivated and active React JS programmers with experience using Firebase, Husky, React Queries, HTML, JavaScript, and CSS."
                                />
                            </div>
                            <div class="container-full mt-5">
                                <div class="work-pro-items">
                                    <div class="row">
                                        {process.map((data) => {
                                            return (
                                                <div class="single-item col-lg-4 col-md-6">
                                                    <div class="item">
                                                        <div class="item-inner">
                                                            <div className='d-flex justify-content-center'>
                                                                <span class=" icon btn btn-circle btn-lg btn-soft-primary android-app-icon">
                                                                    <div className='imgProcess'> {data.img}</div>
                                                                </span>
                                                            </div>
                                                            <h5>{data.heading}</h5>
                                                            <p>{data.paragraph}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactjsSections3