import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/AboutSection4.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const RNSections5 = () => {
    const services = [
        {
            servicesHeading: "Shortlist",
            servicesParagraph: "A BA analyzes your project requirements and recommends the technology that will fit your project best. We share our expert developers' CVs with you, from which you can shortlist several developers.",
            number: 1,
            index: 0
        },
        {
            servicesHeading: "Interview",
            servicesParagraph: "In an interview, you can ask questions to identify the potential of the selected developer. This process will help you answer your doubts and make an informed decision.",
            number: 2,
            index: 1
        },
        {
            servicesHeading: "Onboarding & Introduction",
            servicesParagraph: "The final step of the interview is the meeting of the candidate you selected. Our team helps you onboard the developer to your team and introduces you to the developer to ease communication. We ensure flawless communication by using the latest tools like Trello, Slack, Jira, Webex, Skype, Email, and more.",
            number: 3,
            index: 2
        },
        {
            servicesHeading: "React Native Developers And Programmers?",
            servicesParagraph: "DLK Technologies have a wealth of experience designing & developing cross-platform applications. And this is made possible by the highly-skilled, qualified, and intelligent React Native development team. Contact us at any time and we will be able to turn your idea into reality and launch your product early.",
            number: 3,
            index: 2
        },

    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid iq-tab services-tab what-do-offer">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Hire Dedicated React Native Developers in less than 24 Hours"
                                    paragraph="Become a React Native developer with DLK Technologies certified and solidly educated team. As a client-centric company, we follow a standard cum easy process that takes less than 24 hours to complete when you shortlist our developer and align your choice of developer with your needs."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-6 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY" >{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/hire/reactjs-developer" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Hire React Developer Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RNSections5