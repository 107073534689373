import React from 'react'
import "../../../Assets/Styles/NavSections/AboutSection4.css"

const HiringSectionTitle = ({ heading, paragraph }) => {
    return (
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="section-title1">
                    <h2 >{heading}</h2>
                    <p >{paragraph}</p>
                </div>
            </div>
        </div>
    )
}

export default HiringSectionTitle