import React from 'react'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import RNSections1 from './RNSections.js/RNSections1'
import RNSections2 from './RNSections.js/RNSections2'
import RNSections3 from './RNSections.js/RNSections3'
import RNSections4 from './RNSections.js/RNSections4'
import RNSections5 from './RNSections.js/RNSections5'
import Section8 from '../../../Section8'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'
const ReactNative = () => {
    return (
        <>
         <Helmet>
                <title>Hire React Native Developer for your next app project with DLK React.</title>
                <meta name="description" content="Click here to Hire a React Native developer and build your next high-quality app faster." />
            </Helmet>
            <RouteNav />
            <InsideNav />
            <RNSections1 />
            <RNSections2 />
            <RNSections3 />
            <RNSections4 />
            <RNSections5 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default ReactNative