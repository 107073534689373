import './App.css';
import './index.css'
import LandingPage from './Components/MainSource/LandingPage/LandingPage';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Aboutus from './Components/MainSource/Sections/NavSections/Aboutus/Aboutus';
import Services from './Components/MainSource/Sections/NavSections/Services/Services';
import PluginDev from './Components/MainSource/Sections/NavSections/Services/PluginDev/PluginDev';
import Migration from './Components/MainSource/Sections/NavSections/Services/Migration/Migration';
import Support from './Components/MainSource/Sections/NavSections/Services/Support/Support';
import Integration from './Components/MainSource/Sections/NavSections/Services/Integration/Integration';
import ReactNative from './Components/MainSource/Sections/NavSections/ReactHiring/ReactNativeHiring/ReactNative';
import ReactJs from './Components/MainSource/Sections/NavSections/ReactHiring/ReactJsHiring/ReactJs';
import OurWork from './Components/MainSource/Sections/NavSections/OurWork/OurWork';
import Contactus from './Components/MainSource/Sections/NavSections/ContactUs/Contactus';
import whatsapp from "./Components/Assets/Images/whatsapp.png"
import call from "./Components/Assets/Images/call-now.gif"

function App() {
  return (
    <div>
      <div class="jsn-air-balloon request_quote">
        <a
          href="https://api.whatsapp.com/send?phone=+91 7299951536&amp;text=Hi! I Would Like To Know More About Your Service." target="_blank">
          <img src={whatsapp}
            alt="DLK Career Development Whatsapp"
            title="DLK Career Development Whatsapp"
          />
        </a>
      </div>
      <div class="jsn-air-balloon-phone request_quote_phone">
        <a href="tel:+91-7299951536 " title="+91-7299951536 ">
          <img src={call} alt="DLK Career Development call" title="DLK Career Development call" />
        </a>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/services/react-native-app-development" element={<Services />} />
          <Route path="/services/reactjs-plugin-development" element={<PluginDev />} />
          <Route path="/services/reactjs-migration-services" element={<Migration />} />
          <Route path="/services/react-support-services-maintenance" element={<Support />} />
          <Route path="/services/reactjs-integration-services" element={<Integration />} />

          <Route path="/hire/reactnative-app-developer" element={<ReactNative />} />
          <Route path="/hire/reactjs-developer" element={<ReactJs />} />

          <Route path="/ourwork" element={<OurWork />} />

          <Route path="/contactus" element={<Contactus />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
