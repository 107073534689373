import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection1.css"
import { AiOutlinePhone } from "react-icons/ai"
import RN from "../../../../../../Assets/Images/rn.png"
import react from "../../../../../../Assets/Images/react.png"
import redux from "../../../../../../Assets/Images/redux.png"
import mui from "../../../../../../Assets/Images/mui.png"
import bootstrap from "../../../../../../Assets/Images/bootstrap.png"
import sass from "../../../../../../Assets/Images/sass.png"
import github from "../../../../../../Assets/Images/git.png"
import aws from "../../../../../../Assets/Images/aws.png"
import Heading from '../../../../../Reusable/Heading/Heading'

const SupportServices1 = () => {
    const reactComponentData = [
        {
            img: react,
            width: "35",
            height: "30",
            index: 1
        },
        {
            img: RN,
            width: "17",
            height: "30",
            index: 2
        },
        {
            img: redux,
            width: "33",
            height: "30",
            index: 3
        },
        {
            img: mui,
            width: "38",
            height: "30",
            index: 4
        },
        {
            img: bootstrap,
            width: "28",
            height: "28",
            index: 5
        },
        {
            img: sass,
            width: "31",
            height: "23",
            index: 6
        },
        {
            img: github,
            width: "27",
            height: "26",
            index: 7
        },
        {
            img: aws,
            width: "29",
            height: "18",
            index: 8
        },
    ]
    const [components, setComponents] = useState(reactComponentData)
    return (
        <div class="breadcrumb-area shadow dark bg-fixed text-light">
            <div class="container">
                <Heading title="React Support & Maintenance Services" />
                <div class="col-lg-6 ban-icon-bg inner-services-wp d-md-flex d-lg-flex d-none">
                    <ul>
                        {components.map((data) => {
                            return (
                                <li><img width={data.width} height={data.height} src={data.img} /></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SupportServices1