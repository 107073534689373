import React from 'react'
import "../../../../../Assets/Styles/NavSections/AboutSection4.css"
import { BiNotepad } from "react-icons/bi"
import { RiReservedLine } from "react-icons/ri"
import { MdOutlineRocketLaunch } from "react-icons/md"
import { useState } from 'react'

const AboutSection4 = () => {
    const processData = [
        {
            heading: "Assessment",
            img: <BiNotepad />,
            paragraph: "A testing site will be used to ensure that the previous information remains unchanged during the upgrade process.",
            number: "01",
            index: 0,
        },
        {
            heading: "Reserve",
            img: <RiReservedLine />,
            paragraph: "During execution, we take care to preserve the information of your application and spot it cautiously. Updating an existing web app.",
            number: "02",
            index: 2,
        },
        {
            heading: "Launch",
            img: <MdOutlineRocketLaunch />,
            paragraph: "Prior to delivering your web applications to the client, they must be tested by our QA team.",
            number: "03",
            index: 3,
        }
    ]
    const [process, setProcess] = useState(processData)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="work-process-area overflow-hidden default-padding bottom-less">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-8 offset-lg-2">
                                        <div class="site-heading text-center">
                                            <h4>PROCESS</h4>
                                            <h2>How we works</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-full mt-5">
                                <div class="work-pro-items">
                                    <div class="row">
                                        {process.map((data) => {
                                            return (
                                                <div class="single-item col-lg-4 col-md-6">
                                                    <div class="item">
                                                        <div class="item-inner">
                                                            <div class="icon">
                                                                <i class="flaticon-select">
                                                                   {data.img}
                                                                </i>
                                                                <span>{data.number}</span>
                                                            </div>
                                                            <h5>{data.heading}</h5>
                                                            <p>{data.paragraph}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection4