import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/AboutSection4.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const ReactjsSections4 = () => {
    const services = [
        {
            servicesHeading: "Inquiry",
            servicesParagraph: "Contact our sales representative to discuss your project needs.",
            number: 1,
            index: 0
        },
        {
            servicesHeading: "Select CV",
            servicesParagraph: "Examine the CVs of skilled React JS developers and select the best ones.",
            number: 2,
            index: 1
        },
        {
            servicesHeading: "Take An Interview",
            servicesParagraph: "Analyze the fit of the selected developers with your project by connecting with them virtually.",
            number: 3,
            index: 2
        },
        {
            servicesHeading: "Add Resources To Your Team",
            servicesParagraph: "Outsource your project to the best candidate based on your interview.",
            number: 3,
            index: 2
        },

    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid iq-tab services-tab what-do-offer">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Hire ReactJS Developers Through DLK Technologies in 4 Easy Steps"
                                    paragraph="Hire dedicated ReactJS Developers as per your project needs. Get high-end technical support to build scalable digital products."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-6 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY" >{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Hire React Developer Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactjsSections4