import React, { useRef, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const HiringForm = () => {
    const recaptchaRef = React.createRef();
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <form className='wpcf7-form banner-form init' ref={form} onSubmit={sendEmail}>
            <h4 className='d-flex justify-content-center'>Let's Connect</h4>
            <div className='d-flex flex-md-row flex-column justify-content-between'>
                <div className='col-md-6 col-12 mt-4 p-2'>
                    <input
                        id="ContactForm1_contact-form-name"
                        size="30"
                        required
                        type="text"
                        name="user_name"
                        class="form-control"
                        placeholder='Name'
                    />
                </div>
                <div class=" col-md-6 col-12 mt-md-4 mt-3 p-2">
                    <input
                        id="ContactForm1_contact-form-email"
                        required
                        size="30"
                        type="email"
                        name="user_email"
                        class="form-control"
                        placeholder='Email'
                    />
                </div>
            </div>
            <div class="col-md-12 mt-3 px-2">
                <input
                    required
                    type="tel"
                    class="form-control"
                    id="ContactForm1_contact-form-phone"
                    name="user_phone"
                    size="10"
                    placeholder='Phone'
                />
            </div>
            <div class="col-md-12 mt-4 px-2">
                <textarea
                    required
                    id="ContactForm1_contact-form-email-message"
                    class="form-control"
                    rows="3"
                    name="message"
                    placeholder='Message'
                ></textarea>
            </div>
            <div className='mt-4 d-flex justify-content-center'>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Ld4iCsmAAAAAJvEzSL1kHqGz4QHLROw-Diz9vR_"
                    onChange={onChange}
                />
            </div>
            <div class="col-12 text-center mt-3 px-md-2">
                <button type="submit" id="ContactForm1_contact-form-submit " class="col-12 btn btn-primary submitRN">SUBMIT</button>
            </div>
            <ToastContainer />
        </form>
    )
}

export default HiringForm