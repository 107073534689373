import React from 'react'
import "../../../../../../Assets/Styles/Sections/Section2.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import RNServices5 from "../../../../../../Assets/Images/RN-service5.png"

const IntegrationServices3 = () => {
    return (
        <div id='about' className='ServiceSection5  custom-bottom-service-section overflow-hidden default-padding1'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self-center'>
                        <img src={RNServices5} class="img-fluid" alt="image" width="auto" height="auto" />
                    </div>
                    <div class="col-lg-6 col-md-6 align-self-center iq-choose-info">
                        <h2 class="iq-fw-8 mb-2 iq-mt-40">Our ReactJs Third-Party Integration Services</h2>
                        <p>Our team at DLK Technologies understands how important third-party integration is for businesses to enhance their performance, add new features, remove bugs and deliver outrageous experiences.</p>
                        <ul class="listing-mark">
                            <li>Business-specific integration solutions</li>
                            <li>Integration of third-party plugins</li>
                            <li>Integration services for React-based UIs</li>
                            <li>Integrate social engagement stats of various social media platforms</li>
                            <li>Highly functional ReactJS based plugins</li>
                            <li>One-way data binding and stable code</li>
                            <li>App and Web performance optimization</li>
                            <li>Adding new APIs to enhance features</li>
                            <li>Integration of APIs in ReactJS applications</li>
                            <li>ReactJS integration with payment gateways</li>
                        </ul>
                        <div class="cms-footer-btn">
                            <a class="b2b-btn" href="/hire/reactjs-developer">Hire React Developer</a>
                            <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let's Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntegrationServices3