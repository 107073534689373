import React from 'react'
import "../../../../Assets/Styles/NavSections/Aboutus.css"
import RouteNav from '../RouteNav/RouteNav'
import InsideNav from '../RouteNav/InsideNav'
import AboutSection1 from './AboutSection/AboutSection1'
import AboutSection2 from './AboutSection/AboutSection2'
import AboutSection3 from './AboutSection/AboutSection3'
import AboutSection4 from './AboutSection/AboutSection4'
import AboutSection5 from './AboutSection/AboutSection5'
import Section8 from '../../Section8'
import FooterBottom from './AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'
const Aboutus = () => {
    return (
        <>
            <Helmet>
                <title>ReactJS and Native App Development | DLK React</title>
                <meta name="description" content="DLK React is a full-service ReactJS and Native App Development company with expertise in design and innovation." />
            </Helmet>
            <RouteNav />
            <InsideNav />
            <AboutSection1 />
            <AboutSection2 />
            <AboutSection3 />
            <AboutSection4 />
            <AboutSection5 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default Aboutus