import React from 'react'
import "../../../../../Assets/Styles/NavSections/Services/ServicesSection3.css"
import ser_img3 from "../../../../../Assets/Images/ser_img3.png"
import { FaPhone, FaCubes } from "react-icons/fa"

const ServicesSection3 = () => {
    return (
        <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
                <div class="wpb_wrapper">
                    <div class="video-area extra-padding text-center default-paddings faq-area bg-gray bg-fixed shadows dark text-light">
                        <div class="container">
                            <div class="content">
                                <div class="row">
                                    <div class="col-lg-8 offset-lg-2">
                                        <h5>Why Choose Us</h5>
                                        <h2>We do Outsourced design, <br /> code & develop.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fixed-shape-bottom">
                            <img src={ser_img3} alt="Shape" />
                        </div>
                    </div>
                    <div class="choose-us-area default-padding-bottom">
                        <div class="container">
                            <div class="items-box">
                                <div class="row">
                                    <div class="single-item col-lg-6 col-mg-6">
                                        <div class="item">
                                            <div class="info">
                                                <h4>24/7 Support Team</h4>
                                                <p className='chooseareaPara'>
                                                    Support team is committed to providing exceptional service. Count on us for prompt and reliable assistance, anytime, anywhere. Your success is our priority.                                                </p>
                                                <div class="call">
                                                    <div class="icons">
                                                        <i class="fas fa-phone">
                                                            <FaPhone style={{ color: "#086AD8" }} />
                                                        </i>
                                                    </div>
                                                    <div class="info">
                                                        <span>Helpline</span> +91 7299951536
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-item col-lg-6 col-mg-6 mt-md-0 mt-4">
                                        <div class="item">
                                            <div class="icon">
                                                <i class="fas fa-cubes">
                                                    <FaCubes style={{ color: "#086AD8" }} />
                                                </i>
                                            </div>
                                            <div class="info">
                                                <h4>Smart solutions</h4>
                                                <p className='chooseareaPara'>
                                                    Looking for custom, high-performance web solutions? Our React JS development team provides smart solutions that deliver results and exceed expectations. Let's innovate together.                                                </p>
                                                <a href="/contactus" class="btn-more">Start Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesSection3