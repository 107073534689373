import React from 'react'
import RouteNav from '../RouteNav/RouteNav'
import InsideNav from '../RouteNav/InsideNav'
import ServicesSection1 from "../../NavSections/Services/ServicesSection/ServicesSection1"
import ServicesSection2 from './ServicesSection/ServicesSection2'
import ServicesSection3 from './ServicesSection/ServicesSection3'
import ServicesSection4 from './ServicesSection/ServicesSection4'
import Section8 from '../../Section8'
import FooterBottom from '../Aboutus/AboutSection/FooterBottom/FooterBottom'
import ServicesSection5 from './ServicesSection/ServicesSection5'
import { Helmet } from "react-helmet";

const Services = () => {
    return (
        <>
            <Helmet>
                <title>Best React Native App Development Company in Chennai</title>
                <meta name="description" content="DLK React is one of the best React Native Development Company in Chennai. Our team has expertise in developing web applications using React JS framework." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <RouteNav />
            <InsideNav />
            <ServicesSection1 />
            <ServicesSection2 />
            <ServicesSection5 />
            <ServicesSection3 />
            <ServicesSection4 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default Services