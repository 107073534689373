import React from 'react'
import "../../../../../Assets/Styles/NavSections/AboutSection3.css"
import "../../../../../Assets/Styles/NavSections/AboutSection2.css"
import bannerbg5 from "../../../../../Assets/Images/bannerBg5.png"
import { FaUserCog } from "react-icons/fa"
import { MdSettingsAccessibility } from "react-icons/md"
import { RiUserStarFill } from "react-icons/ri"
import { BsGraphUpArrow } from "react-icons/bs"

const AboutSection3 = () => {
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="features-area overflow-hidden bg-gray default-padding">
                            <div class="fixed-shape shape left bottom">
                                <img src={bannerbg5} alt="Shape" />
                            </div>
                            <div class="container">
                                <div class="row align-center">
                                    <div class="col-lg-5 why-us">
                                        <h5>why choose us</h5>
                                        <h2>Having an experience of successfully executing over 950+ Projects.</h2>
                                        <p>
                                            We will take care of all your web and mobile app needs. We specialize in React development and have successfully deployed 950+ projects. We make it easy for startups, small or mid-sized companies, or enterprises to create apps that help them get results.                                        </p>
                                    </div>
                                    <div class="col-lg-7 features-box text-center">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 item-grid">
                                                <div class="item">
                                                    <i class="flaticon-cogwheel">
                                                        <FaUserCog style={{ color: "#ffffff" }} />
                                                    </i>
                                                    <h5 ><a>Aptitude</a></h5>
                                                    <p>
                                                        DLK Technologies is the perfect company to take care of the needs of your region and give you the best results.                                                    </p>
                                                </div>
                                                <div class="item">
                                                    <i class="flaticon-cloud-storage">
                                                        <RiUserStarFill />
                                                    </i>
                                                    <h5 className='evenCard'><a>Potential</a></h5>
                                                    <p className='evenPara'>
                                                        We have vast experience in React Development, giving premium results to each project
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 item-grid">
                                                <div class="item">
                                                    <i class="flaticon-globe-grid">
                                                        <BsGraphUpArrow />
                                                    </i>
                                                    <h5 className='evenCard'><a >Performance</a></h5>
                                                    <p className='evenPara'>
                                                        React performance ensures efficient, fast, and robust user interfaces that are responsive, optimized, and scalable.
                                                    </p>
                                                </div>
                                                <div class="item">
                                                    <i class="flaticon-backup">
                                                        <MdSettingsAccessibility />
                                                    </i>
                                                    <h5>
                                                        <a >Agency</a></h5>
                                                    <p>
                                                        We has an inventive and creative team that is able to find solutions for you in the React space. We do this through efficient development and dynamism                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection3