import React from 'react'
import "../../../../../Assets/Styles/NavSections/Hiring/JsHiring.css"
import ourWork from "../../../../../Assets/Images/ourWork.png"
const OurWorkSections1 = () => {
    return (
        <div className='iq-breadcrumb'>
            <div className="iq-breadcrumb-info">
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div class="col-lg-6 col-sm-12 col-md-12 align-self-center">
                            <h2 class="iq-fw-8 mb-3">Our Work</h2>
                            <p style={{ paddingTop: "20px" }}>
                                Have A Look At Our Diverse Portfolio With Different Size Of Projects, Scale & Industries. Delivering Complete Mobile App Development Solutions For Startups, Enterprises, and MNCs. Let's Build Your Web / App Today!                            </p>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-self-center">
                            <img src={ourWork} className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurWorkSections1