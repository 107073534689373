import React from 'react'
import "../../../../../Assets/Styles/NavSections/AboutSection2.css"
import "../../../../../Assets/Styles/NavSections/Aboutus.css"
import aboutS1 from "../../../../../Assets/Images/aboutS2.jpg"
import aboutS2 from "../../../../../Assets/Images/aboutS2-1.jpg"

const AboutSection2 = () => {
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="about-area inc-shape default-padding">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 p-5">
                                        <div class="thumb">
                                            <img src={aboutS1} alt="Thumb" className='bgimg' />
                                            <img src={aboutS2} alt="Thumb" className='bgimg' />
                                            <div class="overlay">
                                                <div class="content">
                                                    <h4>20 years of experience</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 offset-lg-1 info">
                                        <h2>Who are we?</h2>
                                        <p className='aboutPara'>
                                            DLK Technologies is one of the top React JS development companies. We have a myriad of experience and a great management team that has been in the industry for 10+ years. We’re also profound in delivering the most complicated and demanding React Development Services, perfect for large-scale enterprise applications.
                                        </p>
                                        <br />
                                        <p className='aboutPara'>
                                            We’ve accomplished over 950+ React projects and have a group of engineers well-versed in the framework. You can depend on DLK Technologies to be able to make your application stand out with their proven track record of success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div></div></div>
    )
}

export default AboutSection2