import React from 'react'
import "../../../../Assets/Styles/NavSections/RouteNav.css"
import { MdLocationPin } from "react-icons/md"
import { HiMailOpen } from "react-icons/hi"
import { BsFillClockFill } from "react-icons/bs"
import { FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa"

const RouteNav = () => {
    return (
        <div id="top" class="top-bar-area inc-pad bg-theme text-light">
            <div class="container">
                <div class="row align-center">
                    <div class="col-lg-6 info item-flex1">
                        <ul>
                            <li>
                                <i class="fas fa-map-marker-alt">
                                    <MdLocationPin style={{ fontSize: "20px" }} />
                                </i>
                                Chennai,India
                            </li>
                            <span className='px-3'> |</span>
                            <li>
                                <i class="fas fa-envelope-open">
                                    <HiMailOpen style={{ fontSize: "20px" }} />
                                </i> info@dlktech.co.in
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 text-right item-flex">
                        <div class="info">
                            <ul>
                                <li>
                                    <i class="fas fa-clock">
                                        <BsFillClockFill style={{ fontSize: "16px" }} />
                                    </i> Office Hours: 9:00 AM – 7:00 PM
                                </li>
                            </ul>
                        </div>
                        <div class="social mt-2">
                            <ul>
                                <li>
                                    <a onClick={() => window.open("https://www.facebook.com/DlkTechnologies/")}>
                                        <i class="fab fa-facebook-f px-2">
                                            <FaFacebookF style={{ color: "#ffffff" }} />
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => window.open("https://twitter.com/dlktechnologie/")}>
                                        <i class="fab fa-twitter px-2">
                                            <FaTwitter style={{ color: "#ffffff" }} />
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => window.open("https://www.linkedin.com/company/dlktechnologies/")}>
                                        <i class="fab fa-linkedin-in px-2">
                                            <FaLinkedin style={{ color: "#ffffff" }} />
                                        </i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RouteNav