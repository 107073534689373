import React, { useState } from 'react'
import ReactSection2Card from '../../ReactSection2Card/ReactSection2Card'

const ReactjsSections2 = () => {
    const services = [
        {
            servicesHeading: "Custom Web Application Development",
            servicesParagraph: "A custom ReactJS web app developed by our React developers will deliver a better user experience with responsive UI and smooth performance.",
            number: 1,
            index: 0
        },
        {
            servicesHeading: "React JS Development From Scratch",
            servicesParagraph: "Build bug-free React JS applications from scratch with DLK Technologies React JS developers.",
            number: 2,
            index: 1
        },
        {
            servicesHeading: "Upgrade Your Existing Applications",
            servicesParagraph: "Streamline your React JS web application's user experience with our dedicated React JS developers",
            number: 3,
            index: 2
        },

    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <div class="section-title">
                                            <h2 >React JS Development Services</h2>
                                            <p >
                                            Your business can achieve success through our performance-based ReactJS development services. Outsource your project to ReactJS developers to get the best output, whether you want to build interactive dashboards or visualization tools, cross-platform web applications, or single-page mobile applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ReactSection2Card
                                    serviceCard={serviceCard}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactjsSections2