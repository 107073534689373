import React from 'react'
import MigrationServices1 from './MigrationServices/MigrationServices1'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import MigrationServices2 from './MigrationServices/MigrationServices2'
import MigrationServices3 from './MigrationServices/MigrationServices3'
import Section8 from '../../../Section8'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import ServicesSection3 from '../ServicesSection/ServicesSection3'
import ServicesSection4 from '../ServicesSection/ServicesSection4'
import { Helmet } from 'react-helmet'

const Migration = () => {
    return (
        <>
            <Helmet>
                <title>Seamless ReactJS Migration Services in Chennai | Expert React Developers</title>
                <meta name="description" content="As the Leading Top React JS Migration Service provider in Chennai, DLK React is highly capable of providing surpasses service to our customer expectations." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <RouteNav />
            <InsideNav />
            <MigrationServices1 />
            <MigrationServices2 />
            <MigrationServices3 />
            <ServicesSection3 />
            <ServicesSection4 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default Migration