import React from 'react'

const ReactSection2Card = ({serviceCard}) => {
  return (
    <div class="row gap-30">
      {serviceCard.map((data) => {
        return (
          <div class="col-lg-4 col-md-6">
            <div class="mt-3 tech-info-main">
              <div class="tech-descript">
                <div class="tech-info and-service">
                  <span class="icon btn btn-circle btn-lg btn-soft-primary home-sec-icon">
                    <span class="number">{data.number}</span>
                  </span>
                </div>
                <div class="tech-info">
                  <h4 class="iq-fw-8">{data.servicesHeading}</h4>
                  <p align="JUSTIFY" >{data.servicesParagraph}</p>
                </div>
              </div>
              <div class="hire-magento-btn">
                <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ReactSection2Card