import React, { useState } from 'react'
import "../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import CarouselCard from '../../../../Reusable/CarouselCard';
const ServicesSection4 = () => {    
    return (
        <div className='mt-5 pt-5 mb-5'>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <div class="section-title">
                            <p class="mb-0 text-uppercase iq-fw-5 iq-ls-2"></p>
                            <h2 class="title iq-fw-8">Our Other Services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <CarouselCard/>
        </div>
    )
}

export default ServicesSection4