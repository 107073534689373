import React from 'react'
import work1_1 from "../../../../../../Assets/Images/work1.1.webp"
import work1_2 from "../../../../../../Assets/Images/work1.2.webp"
import work1_3 from "../../../../../../Assets/Images/work1.3.webp"
import work1_4 from "../../../../../../Assets/Images/work1.4.webp"
import work1_5 from "../../../../../../Assets/Images/work1.5.webp"
import work1_6 from "../../../../../../Assets/Images/work1.6.webp"
import work1_7 from "../../../../../../Assets/Images/work1.7.webp"
import work1_8 from "../../../../../../Assets/Images/work1.8.webp"
import work1_9 from "../../../../../../Assets/Images/work1.9.webp"
import work1_10 from "../../../../../../Assets/Images/work1.10.webp"
import work1_11 from "../../../../../../Assets/Images/work1.11.webp"

import work2_1 from "../../../../../../Assets/Images/work2.1.png"
import work2_2 from "../../../../../../Assets/Images/work2.2.png"
import work2_3 from "../../../../../../Assets/Images/work2.3.png"
import work2_4 from "../../../../../../Assets/Images/work2.4.png"
import work2_5 from "../../../../../../Assets/Images/work2.5.png"
import work2_6 from "../../../../../../Assets/Images/work2.6.png"
import work2_7 from "../../../../../../Assets/Images/work2.7.png"
import work2_8 from "../../../../../../Assets/Images/work2.8.png"

import work3_1 from "../../../../../../Assets/Images/work3.1.jpg"
import work3_2 from "../../../../../../Assets/Images/work3.2.jpg"
import work3_3 from "../../../../../../Assets/Images/work3.3.jpg"
import work3_4 from "../../../../../../Assets/Images/work3.4.jpg"
import work3_5 from "../../../../../../Assets/Images/work3.5.jpg"
import work3_6 from "../../../../../../Assets/Images/work3.6.jpg"
import work3_7 from "../../../../../../Assets/Images/work3.7.jpg"
import work3_8 from "../../../../../../Assets/Images/work3.8.jpg"
import work3_9 from "../../../../../../Assets/Images/work3.9.jpg"
import work3_10 from "../../../../../../Assets/Images/work3.10.jpg"
import work3_11 from "../../../../../../Assets/Images/work3.11.jpg"
import work3_12 from "../../../../../../Assets/Images/work3.12.jpg"
import work3_13 from "../../../../../../Assets/Images/work3.13.jpg"

import work4_1 from "../../../../../../Assets/Images/work4.1.jpg"
import work4_2 from "../../../../../../Assets/Images/work4.2.jpg"
import work4_3 from "../../../../../../Assets/Images/work4.3.jpg"
import work4_4 from "../../../../../../Assets/Images/work4.4.jpg"
import work4_5 from "../../../../../../Assets/Images/work4.5.jpg"
import work4_6 from "../../../../../../Assets/Images/work4.6.jpg"
import work4_7 from "../../../../../../Assets/Images/work4.7.jpg"

import work5_1 from "../../../../../../Assets/Images/work5.1.jpg"
import work5_2 from "../../../../../../Assets/Images/work5.2.jpg"
import work5_3 from "../../../../../../Assets/Images/work5.3.jpg"
import work5_4 from "../../../../../../Assets/Images/work5.4.jpg"
import work5_5 from "../../../../../../Assets/Images/work5.5.jpg"
import work5_6 from "../../../../../../Assets/Images/work5.6.jpg"
import work5_7 from "../../../../../../Assets/Images/work5.7.jpg"
import work5_8 from "../../../../../../Assets/Images/work5.8.jpg"
import work5_9 from "../../../../../../Assets/Images/work5.9.jpg"
import work5_10 from "../../../../../../Assets/Images/work5.10.jpg"

import work6_1 from "../../../../../../Assets/Images/work6.1.jpg"
import work6_2 from "../../../../../../Assets/Images/work6.2.jpg"
import work6_3 from "../../../../../../Assets/Images/work6.3.jpg"
import work6_4 from "../../../../../../Assets/Images/work6.4.jpg"
import work6_5 from "../../../../../../Assets/Images/work6.5.jpg"
import work6_6 from "../../../../../../Assets/Images/work6.6.jpg"
import work6_7 from "../../../../../../Assets/Images/work6.7.jpg"
import work6_8 from "../../../../../../Assets/Images/work6.8.jpg"
import work6_9 from "../../../../../../Assets/Images/work6.9.jpg"
import work6_10 from "../../../../../../Assets/Images/work6.10.jpg"
import work6_11 from "../../../../../../Assets/Images/work6.11.jpg"

import work7_1 from "../../../../../../Assets/Images/work7.1.jpg"
import work7_2 from "../../../../../../Assets/Images/work7.2.jpg"
import work7_3 from "../../../../../../Assets/Images/work7.3.jpg"
import work7_4 from "../../../../../../Assets/Images/work7.4.jpg"
import work7_5 from "../../../../../../Assets/Images/work7.5.jpg"
import work7_6 from "../../../../../../Assets/Images/work7.6.jpg"
import work7_7 from "../../../../../../Assets/Images/work7.7.jpg"
import work7_8 from "../../../../../../Assets/Images/work7.8.jpg"
import work7_9 from "../../../../../../Assets/Images/work7.9.jpg"
import work7_10 from "../../../../../../Assets/Images/work7.10.jpg"

import work8_1 from "../../../../../../Assets/Images/work8.1.webp"
import work8_2 from "../../../../../../Assets/Images/work8.2.webp"
import work8_3 from "../../../../../../Assets/Images/work8.3.webp"
import work8_4 from "../../../../../../Assets/Images/work8.4.webp"
import work8_5 from "../../../../../../Assets/Images/work8.5.webp"
import work8_6 from "../../../../../../Assets/Images/work8.6.webp"
import work8_7 from "../../../../../../Assets/Images/work8.7.webp"
import work8_8 from "../../../../../../Assets/Images/work8.8.webp"
import work8_9 from "../../../../../../Assets/Images/work8.9.webp"
import work8_10 from "../../../../../../Assets/Images/work8.10.webp"
import work8_11 from "../../../../../../Assets/Images/work8.11.webp"
import work8_12 from "../../../../../../Assets/Images/work8.12.webp"
import work8_13 from "../../../../../../Assets/Images/work8.13.webp"

import work9_1 from "../../../../../../Assets/Images/work9.1.jpg"
import work9_2 from "../../../../../../Assets/Images/work9.2.jpg"
import work9_3 from "../../../../../../Assets/Images/work9.3.jpg"
import work9_4 from "../../../../../../Assets/Images/work9.4.jpg"
import work9_5 from "../../../../../../Assets/Images/work9.5.jpg"
import work9_6 from "../../../../../../Assets/Images/work9.6.jpg"
import work9_7 from "../../../../../../Assets/Images/work9.7.jpg"
import work9_8 from "../../../../../../Assets/Images/work9.8.jpg"

import work10_1 from "../../../../../../Assets/Images/work10.1.png"
import work10_2 from "../../../../../../Assets/Images/work10.2.png"
import work10_3 from "../../../../../../Assets/Images/work10.3.png"
import work10_4 from "../../../../../../Assets/Images/work10.4.png"
import work10_5 from "../../../../../../Assets/Images/work10.5.png"
import work10_6 from "../../../../../../Assets/Images/work10.6.png"
import work10_7 from "../../../../../../Assets/Images/work10.7.png"
import work10_8 from "../../../../../../Assets/Images/work10.8.png"

import work11_1 from "../../../../../../Assets/Images/work11.1.png"
import work11_2 from "../../../../../../Assets/Images/work11.2.png"
import work11_3 from "../../../../../../Assets/Images/work11.3.png"
import work11_4 from "../../../../../../Assets/Images/work11.4.png"
import work11_5 from "../../../../../../Assets/Images/work11.5.png"
import work11_6 from "../../../../../../Assets/Images/work11.6.png"
import work11_7 from "../../../../../../Assets/Images/work11.7.png"
import work11_8 from "../../../../../../Assets/Images/work11.8.png"

import work12_1 from "../../../../../../Assets/Images/work12.1.png"
import work12_2 from "../../../../../../Assets/Images/work12.2.png"
import work12_3 from "../../../../../../Assets/Images/work12.3.png"
import work12_4 from "../../../../../../Assets/Images/work12.4.png"
import work12_5 from "../../../../../../Assets/Images/work12.5.png"
import work12_6 from "../../../../../../Assets/Images/work12.6.png"
import work12_7 from "../../../../../../Assets/Images/work12.7.png"
import work12_8 from "../../../../../../Assets/Images/work12.8.png"
import work12_9 from "../../../../../../Assets/Images/work12.9.png"


const imgWork1 = [
    {
        img: work1_1,
        index: 1
    },
    {
        img: work1_2,
        index: 2
    },
    {
        img: work1_3,
        index: 3
    },
    {
        img: work1_4,
        index: 4
    },
    {
        img: work1_5,
        index: 5
    },
    {
        img: work1_6,
        index: 6
    },
    {
        img: work1_7,
        index: 7
    },
    {
        img: work1_8,
        index: 8
    },
    {
        img: work1_9,
        index: 9
    },
    {
        img: work1_10,
        index: 10
    },
    {
        img: work1_11,
        index: 11
    },
]

const imgWork2 = [
    {
        img: work2_1,
        index: 1
    },
    {
        img: work2_2,
        index: 2
    },
    {
        img: work2_3,
        index: 3
    },
    {
        img: work2_4,
        index: 4
    },
    {
        img: work2_5,
        index: 5
    },
    {
        img: work2_6,
        index: 6
    },
    {
        img: work2_7,
        index: 7
    },
    {
        img: work2_8,
        index: 8
    },    
]

const imgWork3 = [
    {
        img: work3_1,
        index: 1
    },
    {
        img: work3_2,
        index: 2
    },
    {
        img: work3_3,
        index: 3
    },
    {
        img: work3_4,
        index: 4
    },
    {
        img: work3_5,
        index: 5
    },
    {
        img: work3_6,
        index: 6
    },
    {
        img: work3_7,
        index: 7
    },
    {
        img: work3_8,
        index: 8
    },   
    {
        img: work3_9,
        index: 9
    }, 
    {
        img: work3_10,
        index: 10
    }, 
    {
        img: work3_11,
        index: 11
    }, 
    {
        img: work3_12,
        index: 12
    }, 
    {
        img: work3_13,
        index: 13
    },  
]

const imgWork4 = [
    {
        img: work4_1,
        index: 1
    },
    {
        img: work4_2,
        index: 2
    },
    {
        img: work4_3,
        index: 3
    },
    {
        img: work4_4,
        index: 4
    },
    {
        img: work4_5,
        index: 5
    },
    {
        img: work4_6,
        index: 6
    },
    {
        img: work4_7,
        index: 7
    },     
]

const imgWork5 = [
    {
        img: work5_1,
        index: 1
    },
    {
        img: work5_2,
        index: 2
    },
    {
        img: work5_3,
        index: 3
    },
    {
        img: work5_4,
        index: 4
    },
    {
        img: work5_5,
        index: 5
    },
    {
        img: work5_6,
        index: 6
    },
    {
        img: work5_7,
        index: 7
    },    
    {
        img: work5_8,
        index: 8
    }, 
    {
        img: work5_9,
        index: 9
    }, 
    {
        img: work5_10,
        index: 10
    },  
]

const imgWork6 = [
    {
        img: work6_1,
        index: 1
    },
    {
        img: work6_2,
        index: 2
    },
    {
        img: work6_3,
        index: 3
    },
    {
        img: work6_4,
        index: 4
    },
    {
        img: work6_5,
        index: 5
    },
    {
        img: work6_6,
        index: 6
    },
    {
        img: work6_7,
        index: 7
    },    
    {
        img: work6_8,
        index: 8
    }, 
    {
        img: work6_9,
        index: 9
    }, 
    {
        img: work6_10,
        index: 10
    },  
    {
        img: work6_11,
        index: 11
    }, 
]

const imgWork7 = [
    {
        img: work7_1,
        index: 1
    },
    {
        img: work7_2,
        index: 2
    },
    {
        img: work7_3,
        index: 3
    },
    {
        img: work7_4,
        index: 4
    },
    {
        img: work7_5,
        index: 5
    },
    {
        img: work7_6,
        index: 6
    },
    {
        img: work7_7,
        index: 7
    },    
    {
        img: work7_8,
        index: 8
    }, 
    {
        img: work7_9,
        index: 9
    }, 
    {
        img: work7_10,
        index: 10
    },   
]

const imgWork8 = [
    {
        img: work8_1,
        index: 1
    },
    {
        img: work8_2,
        index: 2
    },
    {
        img: work8_3,
        index: 3
    },
    {
        img: work8_4,
        index: 4
    },
    {
        img: work8_5,
        index: 5
    },
    {
        img: work8_6,
        index: 6
    },
    {
        img: work8_7,
        index: 7
    },    
    {
        img: work8_8,
        index: 8
    }, 
    {
        img: work8_9,
        index: 9
    }, 
    {
        img: work8_10,
        index: 10
    },   
    {
        img: work8_11,
        index: 11
    }, 
    {
        img: work8_12,
        index: 12
    }, 
    {
        img: work8_13,
        index: 13
    }, 
]

const imgWork9 = [
    {
        img: work9_1,
        index: 1
    },
    {
        img: work9_2,
        index: 2
    },
    {
        img: work9_3,
        index: 3
    },
    {
        img: work9_4,
        index: 4
    },
    {
        img: work9_5,
        index: 5
    },
    {
        img: work9_6,
        index: 6
    } ,
    {
        img: work9_7,
        index: 7
    } ,
    {
        img: work9_8,
        index: 8
    } ,   
]
const imgWork10 = [
    {
        img: work10_1,
        index: 1
    },
    {
        img: work10_2,
        index: 2
    },
    {
        img: work10_3,
        index: 3
    },
    {
        img: work10_4,
        index: 4
    },
    {
        img: work10_5,
        index: 5
    },
    {
        img: work10_6,
        index: 6
    } ,
    {
        img: work10_7,
        index: 7
    } ,
    {
        img: work10_8,
        index: 8
    } ,   
]

const imgWork11 = [
    {
        img: work11_1,
        index: 1
    },
    {
        img: work11_2,
        index: 2
    },
    {
        img: work11_3,
        index: 3
    },
    {
        img: work11_4,
        index: 4
    },
    {
        img: work11_5,
        index: 5
    },
    {
        img: work11_6,
        index: 6
    } ,
    {
        img: work11_7,
        index: 7
    } ,
    {
        img: work11_8,
        index: 8
    } ,   
]

const imgWork12 = [
    {
        img: work12_1,
        index: 1
    },
    {
        img: work12_2,
        index: 2
    },
    {
        img: work12_3,
        index: 3
    },
    {
        img: work12_4,
        index: 4
    },
    {
        img: work12_5,
        index: 5
    },
    {
        img: work12_6,
        index: 6
    } ,
    {
        img: work12_7,
        index: 7
    } ,
    {
        img: work12_8,
        index: 8
    } ,   
    {
        img: work12_9,
        index: 9
    } ,
]
export { 
    imgWork1,
    imgWork2,
    imgWork3 ,
    imgWork4,
    imgWork5,
    imgWork6,
    imgWork7,
    imgWork8,
    imgWork9,
    imgWork10,
    imgWork11,
    imgWork12
}