import React from 'react'
import "../../../../../Assets/Styles/NavSections/Aboutus.css"
import { BsFillArrowRightCircleFill } from "react-icons/bs"

const AboutSection1 = () => {
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="banner-area auto-height text-color bg-gray inc-shape">
                            <div class="item">
                                <div class="container">
                                    <div class="row align-center">
                                        <div class="col-lg-6 p-md-5 p-lg-5 pt-md-0 mt-md-0 pt-5 mt-5 ">
                                            <div class="content px-2" >
                                                {/* <h4>Optimize IT Systems </h4> */}
                                                <h2>Creating a better <strong>React Code</strong></h2>
                                                <p>
                                                    DLK Technologies will develop your web and app with the exceptional Javascript library React.
                                                </p>
                                                <a class="btn circle btn-theme effect btn-md"
                                                    href="/contactus"
                                                >Start Now
                                                    <i class="fas fa-arrow-alt-circle-right px-2">
                                                        <BsFillArrowRightCircleFill />
                                                    </i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 thumb">
                                            <img src="https://wordpressriverthemes.com/tanda/wp-content/uploads/2021/01/3.png" alt="Thumb" className='bgimg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection1