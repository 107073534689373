import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import { BsLayoutTextWindowReverse } from "react-icons/bs"
import { AiOutlineDashboard } from "react-icons/ai"
import { MdOutlineAppRegistration, MdOutlineLocalShipping } from "react-icons/md"
import { VscExtensions } from "react-icons/vsc"
import { TbHandClick } from "react-icons/tb"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const PluginSection2 = () => {
    const services = [
        {
            servicesHeading: "Custom ReactJS Plugin Development",
            servicesParagraph: "We build a custom ReactJS plugin that extends the functionality and capabilities of the website and app seamlessly.",
            img: <AiOutlineDashboard />,
            index: 0
        },
        {
            servicesHeading: "User Experience Design",
            servicesParagraph: "You can hire an Expert ReactJS developer from React Ninjas that can provide native interactions to your ReactJS plugin to make it user-friendly without altering any significant part of the product.",
            img: <MdOutlineAppRegistration />,
            index: 1
        },
        {
            servicesHeading: "ReactJS Plugin Customization",
            servicesParagraph: "If you are unhappy with the performance of your existing plugin and would like to add functionality, you can hire a ReactJS plugin developer from DLK Technologies that will finetune your plugin.",
            img: <BsLayoutTextWindowReverse />,
            index: 2
        },
        {
            servicesHeading: "Third-Party Integration",
            servicesParagraph: "Whether it's integrating payment gateways to provide your users with more payment options or integrating APIs from third-party providers to ensure a seamless user experience, DLK Technologies are always available to help you accomplish this task within your budget and within the given time frame.",
            img: < MdOutlineLocalShipping />,
            index: 3
        },
        {
            servicesHeading: "Add-On/Extension Development",
            servicesParagraph: "You can use Add-ons and extensions to extend your online business offerings and create a memorable customer experience. We DLK Technologies have a team of expert ReactJS plugin developers who can build bug-free, scalable & highly compatible ReactJS plugins that meet your existing product architecture, enhance functionality, and give you a competitive edge.",
            img: <VscExtensions />,
            index: 4
        },
        {
            servicesHeading: "ReactJS Plugin Maintenance",
            servicesParagraph: "Our experienced developers provide great support with continuous engagement to our clients and ensure that our plugins are up-to-date, and compatible with the latest version of ReactJS.",
            img: <TbHandClick />,
            index: 5
        }
    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="ReactJS Plugin Development & API Integrations Services We Offer"
                                    paragraph="Our team of certified developers has built and added numerous successful ReactJS plugins to React Applications over the years. We are an award-winning and ISO 9001 certified ReactJS plugin development company. Aside from providing quick rendering, improved productivity, and reliable ReactJS plugin development services, we’ve perfected this skill."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-icons layout-ic">
                                                            <i class="icomoon icon-layout">
                                                                <div className='service-icons'>
                                                                    {data.img}
                                                                </div>
                                                            </i>
                                                        </div>
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8 mb-2">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY">{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PluginSection2