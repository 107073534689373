import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section1.scss"
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowAltCircleRight, FaReact } from "react-icons/fa"
import { useNavigate } from 'react-router-dom';

const Section1 = () => {
    const recaptchaRef = React.createRef();
    const navigate = useNavigate()
    const form = useRef();

    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const handleClick = () => {
        navigate("/hire/reactjs-developer")
    };

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_z2h60aw',
            'template_mxq95am',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000,
    });
    return (
        <div className="main-banner-area">
            <div className="px-md-5 p-md-0 p-md-4">
                <div className='container col-12 d-flex flex-md-row flex-column justify-content-center'>
                    <div className='col-md-7 col-12' data-aos="fade-up" data-aos-delay="50">
                        <div className='main-banner-content px-5'>
                            <h2 className=''> <strong>React</strong> Development <br className='d-md-flex d-none' /> Company</h2>
                            <p className='pt-md-3 pt-2'>
                                Boost your business's online presence with a professional website.
                            </p>
                            <div className='banner-btnd-flex justify-content-md-start justify-content-center'>
                                <button className='default-btn1 btn-outline-dark d-flex flex-row gap-2' onClick={handleClick}>
                                    <div>Hire React Developers</div>
                                    <div><FaArrowAltCircleRight size="20" className='arrowIcon' /></div>
                                </button>
                            </div>
                            <div class="col-md-8 col-12 text-center mt-5 ">
                                <button type="submit" id="ContactForm1_contact-form-submit"
                                    class="col-12 btn btn-primarys" onClick={() => window.open("https://reactjstraining.dlktechnologies.co.in/")}>
                                    <div className='d-flex justify-content-center  flex-row gap-2'>
                                        <div><FaReact size="35" className='react' /></div>
                                        <div className='d-flex align-items-center reactTitle'>REACT JS & REACT-NATIVE</div>
                                    </div>
                                </button>
                                <div className='certification mt-2'>CERTIFICATION TRAINING</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 pt-md-2 col-12 d-flex justify-content-center align-items-center'>
                        <div class="login " data-aos="fade-up" data-aos-delay="50">
                            <div class="heading">
                                <h4>Enquiry</h4>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className='mt-2 sectionForm'>
                                    <input
                                        id="ContactForm1_contact-form-name"
                                        size="30"
                                        required
                                        type="text"
                                        name="user_name"
                                        class="form-control"
                                        placeholder='Name'
                                    />
                                </div>
                                <div class="mt-2 sectionForm">
                                    <input
                                        id="ContactForm1_contact-form-email"
                                        required
                                        size="30"
                                        type="email"
                                        name="user_email"
                                        class="form-control"
                                        placeholder='Email'
                                    />
                                </div>
                                <div class="mt-2 sectionForm">
                                    <input
                                        required
                                        type="number"
                                        class="form-control"
                                        id="ContactForm1_contact-form-phone"
                                        name="user_phone"
                                        size="10"
                                        placeholder='Phone'
                                    />
                                </div>
                                <div class="mt-2 sectionForm">
                                    <textarea
                                        required
                                        id="ContactForm1_contact-form-email-message"
                                        class="form-control"
                                        rows="3"
                                        name="message"
                                        placeholder='Message'
                                    ></textarea>
                                </div>
                                <div className='mt-2 d-flex justify-content-center'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Ld4iCsmAAAAAJvEzSL1kHqGz4QHLROw-Diz9vR_"
                                        onChange={onChange}
                                    />
                                </div>
                                <div class="col-12 text-center mt-3 ">
                                    <button type="submit" id="ContactForm1_contact-form-submit" class="col-12 btn btn-primary">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Section1