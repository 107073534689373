import React, { useState } from 'react'
import "../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import { BsLayoutTextWindow, BsApple, BsAndroid2, BsCodeSquare, BsRecycle } from "react-icons/bs"
import { GoBook } from "react-icons/go"
import HiringSectionTitle from '../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const ServicesSection2 = () => {
    const services = [
        {
            servicesHeading: "React Native App Development for Android",
            servicesParagraph: "Android React Native App Development DLK Technologies is renowned for its excellent React Native services. Through the use of React Native technology, we outsource React Native Android app developers to build highly competitive apps that outrank your competitors & attract customers.",
            img: <BsAndroid2 />,
            index: 0
        },
        {
            servicesHeading: "React Native App Development for iOS",
            servicesParagraph: "The choice is yours. Whether you're a startup, a small or mid-sized company, or an enterprise, we're here to help you build apps that deliver results. In addition to deploying 50+ iOS apps, we are currently developing several using React Native.",
            img: <BsApple />,
            index: 1
        },
        {
            servicesHeading: "React Native Cross-platform App Development",
            servicesParagraph: "Using modern technologies to deliver state-of-the-art digital products, our React Native developers build interactive, high-quality, beautiful cross-platform mobile applications.",
            img: <GoBook />,
            index: 2
        },
        {
            servicesHeading: "Full-cycle React Native Apps Development",
            servicesParagraph: "In React Native, we use modern technologies, such as React JS, Redux, SQL, NPM, AJAX, JSON, and more, to create a high-performance app. We have expertise in delivering turn-key development in React Native.",
            img: < BsRecycle />,
            index: 3
        },
        {
            servicesHeading: "React Native Consulting",
            servicesParagraph: "No matter what size your business is, we help you develop apps that deliver results on the platform you choose. We have successfully deployed 50+ iOS apps and are still developing several using React Native. We are your technology partner if you have any.",
            img: <BsLayoutTextWindow />,
            index: 4
        },
        {
            servicesHeading: "Code Audit",
            servicesParagraph: "Whether you have an existing app ready to use or that is not fully coded yet, if you are unsatisfied with the work done by your current technical partner. You have the opportunity to hire React Native developers from DLK Technologies that have vast experience in coding and auditing apps built in React Native to detect a bug, fix it and make it compatible as it should be.",
            img: <BsCodeSquare />,
            index: 5
        }
    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">
                                <HiringSectionTitle
                                    heading="Custom React Native App Development Services"
                                    paragraph="We have a team of React Native Developers who are highly skilled, solidly educated with an engineering mindset. Our developers build phenomenal cross-platform apps that are voluptuous & phenomenal. With our React Native development services, you'll be able to outperform your competitors and develop a fast, scalable, and future-focused cross-platform mobile app that outperforms your competitors."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-icons layout-ic">
                                                            <i class="icomoon icon-layout">
                                                                <div className='service-icons'>
                                                                    {data.img}
                                                                </div>
                                                            </i>
                                                        </div>
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8 mb-2">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY">{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus"  data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesSection2