import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import { BsSearch } from "react-icons/bs"
import { AiOutlineDeploymentUnit } from "react-icons/ai"
import { MdOutlineAutoGraph } from "react-icons/md"
import { SiGoogleanalytics } from "react-icons/si"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const MigrationServices2 = () => {
    const services = [
        {
            servicesHeading: "Planning",
            servicesParagraph: "To make you the first choice of your customers, we start with planning. Here, our experienced ReactJS developers analyze your market requirements, use their expertise, and create a custom migration plan based on your needs. In order to maintain the app's taste, we plan the entire roadmap, what components to use at what times, the process to follow, and roles and responsibilities.",
            img: <BsSearch />,
            index: 0
        },
        {
            servicesHeading: "Analyzation",
            servicesParagraph: "In order to develop an enterprise-grade application, we keep the plan at the center and analyze your business's objectives. Our developers have the necessary knowledge of ReactJS migration and use it to perform a seamless migration. We follow a few considerations before we embark on the migration process.",
            img: <SiGoogleanalytics />,
            index: 1
        },
        {
            servicesHeading: "Deployment",
            servicesParagraph: "Planning, analysis, and deployment of the app are all done by us. When you deploy your app, we ensure that its memory is conserved, it performs well, and that elements like ReactDOM.hydrate() are implemented on the client-side. There are some other essential points as well.",
            img: <AiOutlineDeploymentUnit />,
            index: 2
        },
        {
            servicesHeading: "Post Migration Analysis",
            servicesParagraph: "We know what damage could be done to your app if the right assistance and support are not provided to it after the deployment or migration. In addition to providing postmigration analysis and support services to all our clients, we keep a close eye on their performance. If any errors are found in the runtime, we check the key components like server logs, crawler stats, old and new versions, crawl errors, and more.",
            img: < MdOutlineAutoGraph />,
            index: 3
        },
    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">                                
                                <HiringSectionTitle
                                    heading="A well-defined Step-By-Step ReactJS Migration Process"
                                    paragraph="Since we have been in the business for 10+ years of software 
                                    development, our experts have developed the ReactJS migration process 
                                    for a web application & mobile app. To deliver the promise on time, 
                                    the right person follows the process. We have crafted a process to 
                                    satisfy your end user so your business can grow. Here are the steps 
                                     ReactJS developers follow when migrating apps and web apps:"
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-6 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-icons layout-ic">
                                                            <i class="icomoon icon-layout">
                                                                <div className='service-icons'>
                                                                    {data.img}
                                                                </div>
                                                            </i>
                                                        </div>
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8 mb-2">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY">{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MigrationServices2