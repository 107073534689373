import React from 'react'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import SupportServices1 from './SupportServices/SupportServices1'
import SupportServices2 from './SupportServices/SupportServices2'
import SupportServices3 from './SupportServices/SupportServices3'
import ServicesSection3 from '../ServicesSection/ServicesSection3'
import ServicesSection4 from '../ServicesSection/ServicesSection4'
import Section8 from '../../../Section8'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'

const Support = () => {
    return (
        <>
            <Helmet>
                <title>Best React Support & Maintenance Services in chennai | React Native App Development</title>
                <meta name="description" content="DLK React is an expert React Support & Maintenance Services that offers progressive web applications, dynamic web pages, and more." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <RouteNav />
            <InsideNav />
            <SupportServices1 />
            <SupportServices2 />
            <SupportServices3 />
            <ServicesSection3 />
            <ServicesSection4 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default Support