import React from 'react'
import "../../../../../../Assets/Styles/NavSections/FooterBottom.css"
import { BsArrowUp } from "react-icons/bs"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const FooterBottom = () => {
    return (
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 copy">
                        <p>© 2023.Design & Developed by DLK Technologies Pvt Ltd. All Rights Reserved.</p>
                    </div>
                    <div class="col-md-4 text-right link">
                        <div class="menu-footer-menu-container">
                            <ul id="menu-footer-menu" class="menu">
                                <li id="menu-item-310" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-33 current_page_item menu-item-310">
                                    <a href="/" aria-current="page">Home</a>
                                </li>
                                <li id="menu-item-309" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-309">
                                    <a href="/aboutus">About</a>
                                </li>
                                <li id="menu-item-311" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-311">
                                    <a href="/contactus">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="back-to-top" className='col-md-1 d-flex justify-content-end'>
                        <a class="top" id="top">
                            <i class="ion-ios-arrow-thin-up">
                                <Link to="top" smooth={true} duration={100}>
                                    <BsArrowUp />
                                </Link>
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterBottom