import React, { useState } from 'react'
import "../../Assets/Styles/Sections/Section7.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import client1 from "../../Assets/Images/client1.webp"
import client2 from "../../Assets/Images/client2.webp"
import client3 from "../../Assets/Images/client3.webp"
import client4 from "../../Assets/Images/client4.webp"

const Section7 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };
  const client = [
    {
      name: "Beverly Ann",
      review: "DLK Technologies has been a great resource for us as we have gotten our website up and running. The team is always available to answer any questions and help with any issues that may arise. They are incredibly knowledgeable about React development, and we are very satisfied with the results of our project.",
      index: 1,
      img: client1
    },
    {
      name: "Alyne Parker",
      review: "They have provided me with top notch React website development that met all of my requirements. Their team is also very responsive, knowledgeable and professional. Highly recommend!",
      index: 2,
      img: client2,
    },
    {
      name: "AndyeKapoor",
      review: "I am extremely happy with their services. The team is knowledgeable, responsive, and delivers great results. They exceeded my expectations and I would highly recommend them to anyone looking for a reliable React web application development service.",
      index: 3,
      img: client3,
    },
    {
      name: "Aaron",
      review: "React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services.",
      index: 4,
      img: client4
    }
  ]
  const [feedback, setFeedback] = useState(client)
  return (
    <div id='Testimonials' className='standard-services-area bg-gray carousel-shadow default-padding'>
      <div className='container'>
        <div className='row'>
          <div className='site-heading text-center'>
            <h2>What Our Clients Say About Us</h2>
          </div>
          <div className='mt-5 maincarosuel'>
            <Carousel
              autoPlay={true}
              // removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
              responsive={responsive}
              autoPlaySpeed={3000}
              transitionDuration={500}
              showDots={false}
              dotListClass="custom-dot-list-style"
            >
              {feedback.map((data) => {
                return (
                  <div class="owl-item active">
                    <div class="item">
                      <div class="card" style={{height:"450px"}}>
                        <div class="card-body">
                          <blockquote class="icon mb-0">
                            <p className='offerPara mt-3'>{data.review}</p>
                            <div class="blockquote-details mt-4" >
                              <img src={data.img} class="img-fluid img-shap" alt="image" width="60" height="60" />
                              <div class="info " >
                                <h5 class="mb-0">{data.name}</h5>
                                <p class="mb-0"></p>
                              </div>
                            </div>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}              
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section7