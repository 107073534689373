import React from 'react'
import RouteNav from '../../RouteNav/RouteNav'
import InsideNav from '../../RouteNav/InsideNav'
import IntegrationServices1 from './IntegrationServices/IntegrationServices1'
import IntegrationServices2 from './IntegrationServices/IntegrationServices2'
import IntegrationServices3 from './IntegrationServices/IntegrationServices3'
import ServicesSection3 from '../ServicesSection/ServicesSection3'
import ServicesSection4 from '../ServicesSection/ServicesSection4'
import Section8 from '../../../Section8'
import FooterBottom from '../../Aboutus/AboutSection/FooterBottom/FooterBottom'
import { Helmet } from 'react-helmet'

const Integration = () => {
    return (
        <>
            <Helmet>
                <title>Best ReactJS Integration Services in Chennai | React Native App Development Company</title>
                <meta name="description" content="DLK React is a specialized company that offers high-quality React JS Integration Services." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <RouteNav />
            <InsideNav />
            <IntegrationServices1 />
            <IntegrationServices2 />
            <IntegrationServices3 />
            <ServicesSection3 />
            <ServicesSection4 />
            <Section8 />
            <FooterBottom />
        </>
    )
}

export default Integration