import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Hiring/JsHiring.css"
import RN from "../../../../../../Assets/Images/rn.png"
import react from "../../../../../../Assets/Images/react.png"
import redux from "../../../../../../Assets/Images/redux.png"
import mui from "../../../../../../Assets/Images/mui.png"
import bootstrap from "../../../../../../Assets/Images/bootstrap.png"
import sass from "../../../../../../Assets/Images/sass.png"
import github from "../../../../../../Assets/Images/git.png"
import aws from "../../../../../../Assets/Images/aws.png"
import HiringForm from '../../HiringForm/HiringForm'

const ReactNativeHiring = () => {
    const reactComponentData = [
        {
            img: react,
            width: "35",
            height: "30",
            index: 1
        },
        {
            img: RN,
            width: "17",
            height: "30",
            index: 2
        },
        {
            img: redux,
            width: "33",
            height: "30",
            index: 3
        },
        {
            img: mui,
            width: "38",
            height: "30",
            index: 4
        },
        {
            img: bootstrap,
            width: "28",
            height: "28",
            index: 5
        },
        {
            img: sass,
            width: "31",
            height: "23",
            index: 6
        },
        {
            img: github,
            width: "27",
            height: "26",
            index: 7
        },
        {
            img: aws,
            width: "29",
            height: "18",
            index: 8
        },
    ]
    const [components, setComponents] = useState(reactComponentData)
    return (
        <div className='iq-breadcrumb'>
            <div className="iq-breadcrumb-info">
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div class="col-lg-6 col-sm-12 col-md-12">
                            <h2 class="iq-fw-8 mb-3">Hire React Native Developer</h2>
                            <p style={{ paddingTop: "20px", letterSpacing: "1px" }}>We provide a “C” level of support to our clients across the globe. Hire React Native developers from DLK Technologies possessing experience in building award-winning applications for both platforms Android & iOS. By creating apps that are scalable, secure, add value, and boost profits, we turn your idea into a reality.</p>
                            <div class="cms-banner-btn d-flex flex-md-row flex-column ">
                                <a class="m-store mb-md-0 mb-3" href="/hire/reactjs-developer">Hire React Developer</a>
                                <a href="/contactus" data-name="Get a Call Back">Get a Call Back</a>
                            </div>
                            <div class="ban-icon-bg inner-services-wp d-md-flex d-lg-flex d-none">
                                <ul>
                                    {components.map((data) => {
                                        return (
                                            <li><img width={data.width} height={data.height} src={data.img} /></li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 mt-md-0 mt-5'>
                            <div className='formData wpcf7 px-5-1'>
                                <HiringForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactNativeHiring