import React from 'react'
import "../../../../../../Assets/Styles/Sections/Section2.css"
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import RNServices4 from "../../../../../../Assets/Images/RN-service4.png"

const SupportServices3 = () => {
    return (
        <div id='about' className='ServiceSection5  custom-bottom-service-section overflow-hidden default-padding1'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self-center'>
                        <img src={RNServices4} class="img-fluid" alt="image" width="auto" height="auto" />
                    </div>
                    <div class="col-lg-6 col-md-6 iq-choose-info align-self-center">
                        <h2 class="iq-fw-8 mb-2 iq-mt-40">Our Comprehensive React Support &amp; Maintenance Services</h2>
                        <ul class="listing-mark">
                            <li>Monitoring of performance on an ongoing basis</li>
                            <li>Security for React Apps</li>
                            <li>Optimizing the speed of a React app</li>
                            <li>Upgrade to the latest version of React</li>
                            <li>Migration with React</li>
                            <li>Implement A/B Testing</li>
                            <li>React Version Upgrades</li>
                            <li>React Integration</li>
                            <li>Fix broken links/fix bugs</li>
                            <li>React support and maintenance services</li>
                        </ul>
                        <div class="cms-footer-btn ">
                            <a class="b2b-btn" href="/hire/reactjs-developer">Hire React Developer</a>
                            <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let's Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportServices3