import React from 'react'
import "../../../../../Assets/Styles/Sections/Section2.css"
import "../../../../../Assets/Styles/NavSections/Services/ServicesSection4.css"
import RNServices from "../../../../../Assets/Images/RN-service-1.png"

const ServicesSection5 = () => {
    return (
        <div id='about' className='ServiceSection5  custom-bottom-service-section overflow-hidden default-padding1'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 align-self-center'>
                        <img src={RNServices} class="img-fluid" alt="image" width="auto" height="auto" />
                    </div>
                    <div className='col-lg-6 col-md-6 align-self-center iq-choose-info'>
                        <h2 class="iq-fw-81 mb-2 iq-mt-40">Successful Companies using React Native</h2>
                        <p>
                            We are here to help you if you have an idea requiring React Native Development skills. Please call us to discuss your project and expertise. A DLK Technologies expert will contact you according to your time zone. Get your idea shaped into a final product by hiring one of our profiles.
                        </p>
                        <p>At DLK Technologies, we strive to utilize the power of React Native, native platforms, and application integration knowledge to help our customers achieve automation through modern tools such as the Cloud. We are dedicated to optimizing and streamlining our client's operations, minimizing expenses and increasing returns. Our React Native coders understand that effective coordination between systems is essential for all businesses and therefore, are fully equipped to craft smart APIs and implement them in order to provide an incredible customer experience.
                        </p>
                        <div class="cms-footer-btn">
                            <a class="b2b-btn" href="/hire/reactjs-developer">Hire React Developer</a>
                            <a href="/contactus" data-name="Let’s discuss">Let's Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesSection5