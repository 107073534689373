import React, { useRef, useState } from 'react'
import "../../../../../../Assets/Styles/Sections/Section4.css"
import ModalPage from '../../../../ModalPage'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const RNSections3 = () => {
  const offerData = [
    {
      heading: "Dedicated Resource Model",
      list1: "Your project will be handled by the right react native developers.",
      list2: "Your team is completely under your control.",
      list3: "Hire React Native Developer for short & long-term projects.",
      index: 0
    },
    {
      heading: "Managed Project Model",
      list1: "The idea, the execution, and the delivery",
      list2: "On-time delivery and mandatory NDA",
      list3: "Guaranteed Transparency",
      index: 1
    },
    {
      heading: "Managed Resource Model",
      list1: "We set up & manage your remote team.",
      list2: "The Project will be completed successfully, on time & within budget",
      list3: "Response to your query on time",
      index: 2
    },
  ]

  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offers, setOffers] = useState(offerData)
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs.sendForm(
      'service_z2h60aw',
      'template_mxq95am',
      form.current,
      'ymjMeKsCwpSffaKHA'
    )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully! We will get back to you soon.");
        e.target.reset();
        setIsModalOpen(false);
        console.log("message sent");
      }, (error) => {
        toast.error("Message Sending Failed! Please try again.")
        console.log(error.text);
      });
  };

  return (
    <div className='iq-tab services-tab what-do-offer'>
      <div class="container">
        <div class="row services-offers">
          <div class="col-md-5 position-sticky">
            <div class="section-title">
              <h2 class="title iq-fw-8 mt-3">Flexible Engagement Models</h2>
              <p class="mt-4 mb-3 offerPara ">
                When you are unsure about your requirement and the project is quite large, our flexible engagement model is the right fit for you. Your engagement model can be tailored according to your project requirements, and resources can be added, replaced, or removed according to your ongoing project requirements.              </p>
              <div class="cms-banner-btn">
                <a class="m-store" onClick={showModal}>Hire React Developer</a>
                <a onClick={showModal}>Contact us</a>
              </div>
              <ModalPage
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                handleCancel={handleCancel}
                showModal={showModal}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                sendEmail={sendEmail}
                form={form}
              />
            </div>
          </div>
          <div class="col-md-7">
            <div class="container tab-content">
              {offers.map((data) => {
                return (
                  <div class="container main-content">
                    <div class="row justify-content-between">
                      <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                        <h2 class="title iq-fw-8">{data.heading}</h2>
                        <ul class="listing-mark mt-4">
                          <li>{data.list1}</li>
                          <li>{data.list2}</li>
                          <li>{data.list3}</li>
                        </ul>
                        <a onClick={showModal} data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RNSections3