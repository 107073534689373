import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Assets/Styles/NavSections/Services/ServicesSection4.css"

const CarouselCard = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1
        }
    };

    const serviceCustomData = [
        {
            label: "ReactJS Integration Services",
            paragraph: "Whether it is to realign the business application as per the need, enhance the performance...",
        },
        {
            label: "React Native App Development",
            paragraph: "Create a Feature-Rich Application ensures a digital experience for users different businesses leveraging React Native...",
        },
        {
            label: "React Support & Maintenance Services",
            paragraph: "DLK Technologies provide end-to-end support & maintenance solutions to keep our client’s web & mobile...",
        },
        {
            label: "Reactjs Migration Services",
            paragraph: "Migrate or upgrade your existing web application from the existing technology to ReactJS by leveraging...",
        },
        {
            label: "Reactjs Plugin Development",
            paragraph: "We develop 100% customized, business-focused, and secure ReactJS plugins to add a host of powerful...",
        },
    ]
    const [serviceCustom, setServiceCustom] = useState(serviceCustomData)
    return (
        <div className='container mt-4'>
            <Carousel
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
                responsive={responsive}
                autoPlaySpeed={3000}
                transitionDuration={500}
                showDots={false}
                dotListClass="custom-dot-list-style"
            >
                {serviceCustom.map((data) => {
                    return (
                        <div class="card owl-item" style={{ marginRight: "20px" }}>
                            <div class="item">
                                <div class="main-blog services">
                                    <div class="blog-detail">
                                        <h5 class="mt-1 iq-fw-8">{data.label}</h5>
                                        {data.paragraph}
                                    </div>
                                    <div class="hire-magento-btn">
                                        <a class="h-black" href="/services/integration">Learn More</a>
                                        <a href="/contactus">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}               
            </Carousel>
        </div>
    )
}

export default CarouselCard