import React, { useState } from 'react'
import "../../../../../../Assets/Styles/NavSections/Services/ServicesSection2.css"
import { BsSearch } from "react-icons/bs"
import { CgWebsite } from "react-icons/cg"
import { MdOutlineLocalShipping, MdOutlineSupportAgent } from "react-icons/md"
import { RiTeamLine } from "react-icons/ri"
import { AiOutlineComment } from "react-icons/ai"
import HiringSectionTitle from '../../../../../Reusable/HiringSectionTitle/HiringSectionTitle'

const SupportServices2 = () => {
    const services = [
        {
            servicesHeading: "Elite Support & Maintenance",
            servicesParagraph: "Providing support and maintenance services is the responsibility of DLK Technologies developers. We do it all. We fix bugs, install SSL, optimize React App performance, update React security, remove broken links, update content, upgrade React versions, migrate React apps, and audit React apps.",
            img: <MdOutlineSupportAgent />,
            index: 0
        },
        {
            servicesHeading: "Maintenance And Support Package",
            servicesParagraph: "Whether you are a startup, small business, medium-sized business, government agency, or enterprise-level business, we have designed flexible maintenance & support packages tailored to your business requirements in order to meet all your specific business needs.",
            img: <BsSearch />,
            index: 1
        },
        {
            servicesHeading: "Absolutely Devoted Team",
            servicesParagraph: "As DLK Technologies, we offer “fun with work” that makes our engineers committed to their work. Our highly-experienced React developers are always ready to take up any challenge as an opportunity and strive to deliver only the best based on business domain needs, client expectations and strategy.",
            img: <RiTeamLine />,
            index: 2
        },
        {
            servicesHeading: "Web & Mobile App Maintenance",
            servicesParagraph: "Our company provides an end-to-end support and maintenance solution for web & mobile apps that ensures your app is delivering the best user experience. We also ensure it is safe from attacks and adaptable to evolving tech and market needs. We aim to keep you at the top of your game.",
            img: < CgWebsite />,
            index: 3
        },
        {
            servicesHeading: "Solve Bug Problems",
            servicesParagraph: "To solve bugs in real time, you need solid support and maintenance services. They are going to come often at an unprecedented time. For all business domains, DLK Technologies provides bug-free, efficient and round-the-clock maintenance and support for single-page applications, portal web applications, static applications, eCommerce applications, and custom applications.",
            img: <MdOutlineLocalShipping />,
            index: 4
        },
        {
            servicesHeading: "Real & Concrete Expertise",
            servicesParagraph: "Our React developer team has gained expertise spending years in React app development, integration, migration and maintenance & support for versatile businesses. In addition to providing uncompromised quality maintenance and support services for any size business across the globe, DLK Technologies developers have earned the trust of many brands.",
            img: < AiOutlineComment />,
            index: 5
        },
    ]
    const [serviceCard, setServicesCard] = useState(services)
    return (
        <div class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <section class="newstore-development col-technical inner-content">
                            <div class="container">                               
                                <HiringSectionTitle
                                    heading="React Maintenance Services"
                                    paragraph="If you own a React application, we can keep it up to date and compatible with future changes with our React support and maintenance service. Taking into account the business needs of start-ups, small and medium-sized businesses, governments, agencies, enterprises, and corporations, our React developers have developed tailored and affordable React Maintenance Packages."
                                />
                                <div class="row gap-30">
                                    {serviceCard.map((data) => {
                                        return (
                                            <div class="col-lg-6 col-md-6">
                                                <div class="mt-3 tech-info-main">
                                                    <div class="tech-descript">
                                                        <div class="tech-icons layout-ic">
                                                            <i class="icomoon icon-layout">
                                                                <div className='service-icons'>
                                                                    {data.img}
                                                                </div>
                                                            </i>
                                                        </div>
                                                        <div class="tech-info">
                                                            <h4 class="iq-fw-8 mb-2">{data.servicesHeading}</h4>
                                                            <p align="JUSTIFY">{data.servicesParagraph}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hire-magento-btn">
                                                        <a href="/contactus" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s Connect to Discuss More">Let’s Connect to Discuss More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportServices2